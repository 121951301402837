import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const isCoach_str = localStorage.getItem('is_coach');


  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated && isCoach_str !== 'TBD') {
          return <Redirect to="/" />;
        } 
        
        if (props.match.path === '*') {
          if (isCoach_str === 'true') {
            return <Redirect to="/manage-teams" />;
          } else if (isCoach_str !== 'TBD') {
            return <Redirect to="/my-film" />;
          }
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
