const COMPANY_NAME = {
    FIRST: "R u b i c o n",
    //LAST: "Robotics",
}
const COMPANY_DESC = "Rubicon designs filming robots and complementary analytics software to bring the highest degree of automation and precision to your team's practices, working hand in hand with coaches to help athletes achieve their fullest performance potential."

const BASE_URL = 'https://server.rubiconrobotics.com';
// const BASE_URL = 'https://server.rubiconrobotics.com';

export { COMPANY_NAME, COMPANY_DESC, BASE_URL };
