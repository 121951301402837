import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { useHistory, useLocation, Redirect } from "react-router-dom";
import "./GetStartedAthlete.css";
import HomeLogo from "../../components/HomeLogo";
import GetStartedTemplate from "../../components/GetStartedTemplate";
import { BASE_URL } from "../../constants/company-info";

function GetStartedAthlete() {
  const [teams, setTeams] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [joinWithoutTeam, setJoinWithoutTeam] = useState(false);
  const [coachesForTeam, setCoachesForTeam] = useState([]);
  const [selectedCoaches, setSelectedCoaches] = useState([]);

  const history = useHistory();

  const location = useLocation();
  const userId = location.state?.userId;
  const sport = location.state?.sport;
  const role = location.state?.role;

  const isCoach_str = localStorage.getItem('is_coach');

  if (!userId || isCoach_str !== 'TBD') {
    if (isCoach_str === 'false') {
      return <Redirect to='/my-film' />
    }
    else {
      return <Redirect to='/manage-teams' />
    }
  };

  useEffect(() => {
    loadAllTeams();
  }, []);

  const loadAllTeams = async () => {
    try {
      const response = await fetch(BASE_URL + '/api/get_all_teams/', {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const teamsWithIdAndName = data.map(team => ({
        value: team.id,
        label: team.name,
        id: team.id,
      }));
      setAllTeams(teamsWithIdAndName);
    } catch (error) {
      console.error('Error fetching all teams: ', error);
    }
  };

  // Get all coaches for a given team
  const loadCoaches = async (teamNames) => {
    setCoachesForTeam([]);

    let allCoaches = [];
    try {
      for (let teamName of teamNames) {
        const response = await fetch(`${BASE_URL}/api/get_coaches/?team_name=${teamName}`, {
          method: 'GET',
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        allCoaches = allCoaches.concat(data.map(coach => ({ value: coach.user_id, label: coach.name })))
          .filter((coach, index, self) =>
            index === self.findIndex((t) => (
              t.value === coach.value && t.label === coach.label
            ))
          );
      }
      setCoachesForTeam(allCoaches);
    } catch (error) {
      console.error('Error fetching coaches: ', error);
    }
  };

  // Register an athlete
  const registerAthlete = async () => {
    const teamIds = teams.map(selectedTeam => selectedTeam.value);
    const coachIds = selectedCoaches.map(coach => coach.value);
    const data = {
      "user": userId,
      "best_score": 0.0,
      "teams": teamIds,
      "coaches": coachIds,
      "description": "athlete desc here"
    };

    try {
      const response = await fetch(BASE_URL + '/api/athletes/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      localStorage.removeItem('is_coach');
      console.error('Error creating Athlete: ', error);
      return null;
    }
  };

  const handleNextPage = async () => {
    localStorage.setItem('is_coach', 'false');
    // Register the athlete
    await registerAthlete();

    history.push({
      pathname: `/my-film/`,
      state: { userId: userId }
    });
  };

  const handleChange = (selectedOptions) => {
    setTeams(selectedOptions || []);
    
    const teamNames = selectedOptions.map(option => option.label);
    if (teamNames.length > 0) {
      loadCoaches(teamNames);
    } else {
      setCoachesForTeam([]);
    }
  };

  const handleChangeCoaches = (selectedOptions) => {
    setSelectedCoaches(selectedOptions || []);
  };

  const handleRadioChange = (e) => {
    if (!e.target.checked) {
      setJoinWithoutTeam(false);
    } else {
      setJoinWithoutTeam(true);
      setTeam("");
    }
  };

  const allFieldsFilled = ((teams.length !== 0) && (selectedCoaches.length !== 0)) || joinWithoutTeam;

  const formContent = (
    <div className="form">
      <div className="team-option" style={{"marginTop": "-15px"}}>Join a Team</div>
      <label style={{"marginTop": "15px"}}>Select a team or join as an individual</label>
      <Select
        className="select-team"
        placeholder="Select or type a team name"
        isClearable
        isSearchable
        name="team"
        options={allTeams}
        onChange={handleChange}
        isDisabled={joinWithoutTeam}
        isMulti
        value={teams}
      />

      {/* Radio Button for Private Training */}
      <div className="private-training-option">
        <input
          type="checkbox"
          id="privateTraining"
          name="teamJoinOption"
          checked={joinWithoutTeam}
          onChange={handleRadioChange}
        />
        <label className="private-training" htmlFor="privateTraining">Private Training (join without a team)</label>
      </div>

      <div className="team-option">Select coaches</div>
      <label style={{"marginTop": "15px"}}>Select your coach(es)</label>
      <Select
        className="select-team"
        placeholder="Select or type coach names"
        isClearable
        isSearchable
        name="coaches"
        options={coachesForTeam}
        value={selectedCoaches}
        onChange={handleChangeCoaches}
        isDisabled={coachesForTeam.length === 0 || joinWithoutTeam}
        isMulti
      />

      <button
          className="submit-log-in"
          disabled={!allFieldsFilled}
          onClick={handleNextPage}
          style={{"marginTop": "30px"}}
        >
          Next
        </button>
    </div>
  );

  return (
    <div className="login-page">
      <div className='background'></div>
      <HomeLogo />
      <GetStartedTemplate
        title={"Get Started!"}
        form={formContent}
      />
    </div>
  );
}

export default GetStartedAthlete;
