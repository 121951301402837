import React from "react";
import "./TeamBox.css";

function TeamBox({ name, onClick, selected }) {

  const className = `team-box ${selected ? 'tbox-selected' : ''}`;

  return (
    <a style={{ textDecoration: 'none' }}>
        <div onClick={onClick} className={className}>
            {name}
        </div>
    </a>
    
  );
}

export default TeamBox;
