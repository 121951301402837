import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./LogIn.css";
import HomeLogo from "../../components/HomeLogo";
import LogInTemplate from "../../components/LogInTemplate";
import AuthContext from "../../context/AuthContext";
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye';
import { BASE_URL } from "../../constants/company-info";

// helper function to extract cookie
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}

// Retrieve CSRF Token
async function getCsrfToken() {
  try {
      const dummyResponse = await fetch(BASE_URL + '/api/csrf_cookie', {
          credentials: 'include',
      });
      if (!dummyResponse.ok) {
          console.error('HTTP Error setting CSRF cookie');
          return null;
      }
      const csrfToken = getCookie('csrftoken');
      // console.log('CSRF Token:', csrfToken);
      return csrfToken;
  } catch (error) {
      console.error('There was a problem with your fetch csrf_cookie operation:', error);
      return null;
  }
}

function LogIn() {
  const history = useHistory();
  const { login } = useContext(AuthContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const isCoach = localStorage.getItem('is_coach') === 'true';

    if (token) {
      if (isCoach) {
        history.push('/manage-teams');
      } else if (localStorage.getItem('is_coach') === 'TBD') {
        history.push('/sign-up');
      } else {
        history.push('/my-film');
      }
    }
  }, [history]);
  
  const handleLogin = async () => {
    // GET request to retrieve token
    const csrfToken = await getCsrfToken() || 'N/A';
    if (!csrfToken) {
        console.error('Failed to retrieve CSRF token.');
        return;
    }

    fetch(BASE_URL + '/api/login', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({ username, password }),
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setLoginError(data.error);
        }
        else {
          setLoginError('');

          login(data.token);
          localStorage.setItem('is_coach', data.is_coach);
          localStorage.setItem('userId', data["user_id"])

          // Route on successful login
          if (data["is_coach"]) {
            localStorage.setItem('name', data["name"])
            history.push({
              pathname: `/manage-teams/`,
              state: { userId: data["user_id"],
                name: data["name"] }
            });
          } else {
            history.push({
              pathname: `/my-film/`,
              state: { userId: data["user_id"] }
            });
          }
        }
      })
      .catch(error => {
          console.error('Error signing in: ', error);
      });
  };

  const allFieldsFilled = username && password;

  const formContent = (
      <div className="form">
        <label>Enter your username or email address</label>
        <input
          type="email"
          className="form-control mt-1"
          placeholder="Username or email address"
          value={username}
          onChange={(e) => {setUsername(e.target.value); setLoginError('');}}
        />
        <label>Enter your password</label>
        <div className="input-group">
          <input
            type={passwordVisible ? 'text' : 'password'}
            className="form-control"
            placeholder="Password"
            value={password}
            onChange={(e) => {setPassword(e.target.value); setLoginError('');}}
          />
          <span className="input-group-icon" onClick={() => setPasswordVisible(!passwordVisible)}>
            <Icon icon={passwordVisible ? eye : eyeOff} size={20} />
          </span>
        </div>
        <Link className='forgot-password' to="/desktop-default">forgot password</Link>

        {loginError && <div className="error-message">{loginError}</div>}
        <button
          className="submit-log-in"
          disabled={!allFieldsFilled}
          onClick={handleLogin}
        >
          Log in
        </button>
      </div>
  );
  
  return (
    <div className="login-page">
        <div className='background'></div>
        <HomeLogo/>
        <LogInTemplate 
            needOrHaveAnAccount = {"Don't have an account?"}
            signUpOrLogIn = {<Link to="/sign-up">Sign up</Link>}
            title = {"Log in"}
            form = {formContent}
        />
    </div>
  );
}

export default LogIn;
