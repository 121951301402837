import React from "react";
import SignUpLogInButtonCluster from "../../components/SignUpLogInButtonCluster";
import HomeLogo from "../../components/HomeLogo";

import "./Checkout.css";


function Checkout() {

  return (
    <div className="checkout-page">
        <div className="top-bar">
          <HomeLogo/>
          <SignUpLogInButtonCluster/>
        </div>
    </div>
  );
}

export default Checkout;
