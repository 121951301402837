import React from 'react';
import './HomeLogo.css';

function HomeLogo() {
  return (
    <div className="home-header-area">
      <div className="logo-area">
        <a href="/">
          <img className="logo-transparent-white-1-1" src={require("../../static/img/logo-transparent-white-1@2x.png")} alt="logo-transparent-white 1" />
        </a>
      </div>
    </div>
  );
}

export default HomeLogo;
