import React from "react";
import "./GetStartedTemplate.css";

function LogInTemplate(props) {
const {title, form} = props

  return (
    <div className="login-template">
        <div className='welcome'>Welcome to Rubicon Robotics!</div>
        <div className="log-sign-title"><h1>{title}</h1></div>
        {form}
    </div>
  );
}

export default LogInTemplate;
