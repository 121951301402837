import React from "react";
import Group5 from "../Group5";
import "./AccountSummary.css";

function AccountSummary(props) {
  const {
    account,
    shipping,
    payment,
    accountDetails,
    emailAddress,
    emailMyemailCom,
    checkboxCircleFill2,
    password,
    text13,
    checkboxCircleFill1,
    registerForAccount,
    overlapGroup1,
    login,
    line3,
    cancelOrder,
    overlapGroup,
    shippingDetails,
    group5Props,
  } = props;

  return (
    <div className="account-summary">
      <div className="flex-row-5">
        <div className="account">{account}</div>
        <Group5 />
        <div className="shipping neurialgrotesk-medium-gray-20px">{shipping}</div>
        <Group5 className={group5Props.className} />
        <div className="payment neurialgrotesk-medium-gray-20px">{payment}</div>
      </div>
      <div className="account-details neurialgrotesk-medium-black-20px">{accountDetails}</div>
      <div className="email-address neurialgrotesk-medium-slate-gray-16px">{emailAddress}</div>
      <div className="overlap-group4-3">
        <div className="emailmyemailcom neurialgrotesk-medium-outer-space-16px">{emailMyemailCom}</div>
        <img className="checkbox-circle-fill-2" src={checkboxCircleFill2} alt="checkbox-circle-fill 2" />
      </div>
      <div className="password-2 neurialgrotesk-medium-slate-gray-16px">{password}</div>
      <div className="overlap-group3-4">
        <div className="text-13 neurialgrotesk-medium-outer-space-16px">{text13}</div>
        <img className="checkbox-circle-fill-1" src={checkboxCircleFill1} alt="checkbox-circle-fill 1" />
      </div>
      <div className="group-4">
        <div className="register-for-account neurialgrotesk-medium-outer-space-16px">{registerForAccount}</div>
        <div className="overlap-group1-9" style={{ backgroundImage: `url(${overlapGroup1})` }}>
          <div className="login neurialgrotesk-medium-white-lilac-16px">{login}</div>
        </div>
      </div>
      <img className="line-3-3" src={line3} alt="Line 3" />
      <div className="flex-row-6">
        <div className="cancel-order">{cancelOrder}</div>
        <div className="overlap-group-14" style={{ backgroundImage: `url(${overlapGroup})` }}>
          <div className="shipping-details neurialgrotesk-medium-white-lilac-16px">{shippingDetails}</div>
        </div>
      </div>
    </div>
  );
}

export default AccountSummary;
