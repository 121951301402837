import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import './FullHomeHeader.css';
import LogInButton from '../LogInButton';
import SignUpButton from '../SignUpButton';
import ProfileIcon from '../ProfileIcon';

function FullHomeHeader() {
  const userId = localStorage.getItem('userId');
  const isCoach_str = localStorage.getItem('is_coach');
  const isCoach = isCoach_str ? (isCoach_str === 'true'): false;
  const name = localStorage.getItem('name');
  const history = useHistory();
  
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleMyTeamsClick = () => {
    history.push({
      pathname: `/manage-teams/`,
      state: { userId: userId,
        name: name }
    });
  }

  return (
    <div className="home-header-area">
      <div className="logo-area">
        <a href="/">
          <img className="logo-transparent-white-1-1" src={require("../../static/img/logo-transparent-white-1@2x.png")} alt="logo-transparent-white 1" />
        </a>
      </div>

      {!userId && (
        <div className="buttons-area">
          <LogInButton />
          <SignUpButton />
        </div>
      )}
      {userId && (
        <>
          <ProfileIcon userId={userId} onLogout={handleLogout}/>
          {isCoach && (
            <button className="myTeams-button-home-header" onClick={() => handleMyTeamsClick()}> My Teams </button>
          )}
        </>
      )}
    </div>
  );
}

export default FullHomeHeader;
