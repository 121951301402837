import React from "react";
import { Link } from "react-router-dom";
import "./Checkout4.css";

function Checkout4(props) {
  const { thankYouForYourOrder, pleaseCheckYourEm, iconTickCircle, backToProducts, logoTransparentWhite1 } = props;

  return (
    <div className="container-center-horizontal">
      <div className="checkout-4 screen">
        <div className="overlap-group-15">
          <div className="ellipse-2-10"></div>
          <div className="ellipse-1-8"></div>
          <div className="frame-21">
            <h1 className="thank-you-for-your-order">{thankYouForYourOrder}</h1>
            <p className="please-check-your-em">{pleaseCheckYourEm}</p>
            <img className="icon-tick-circle" src={iconTickCircle} alt="icon &#34;tick circle&#34;" />
            <div className="frame-20-2">
              <div className="back-to-products">{backToProducts}</div>
            </div>
          </div>
          <Link to="/desktop-default">
            <img className="logo-transparent-white-1-9" src={logoTransparentWhite1} alt="logo-transparent-white 1" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Checkout4;
