import "./App.css";
import React from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import DesktopDefault from "./Pages/DesktopDefault";
import SignUp from "./Pages/SignUp";
import Checkout1 from "./components/Checkout1";
import Checkout4 from "./components/Checkout4";
import LogIn from "./Pages/LogIn";
import ExploreProducts from "./Pages/ExploreProducts";
import ManageTeams from "./Pages/ManageTeams";
import MyFilm from "./Pages/MyFilm";
import SwimBot from "./Pages/Swimbot";
import Checkout from "./Pages/Checkout";
import GetStarted from "./Pages/GetStarted";
import GetStarted2 from "./Pages/GetStarted2";
import GetStartedCoach from "./Pages/GetStartedCoach";
import GetStartedAthlete from "./Pages/GetStartedAthlete";
import CoachProfile from "./Pages/CoachProfile";
import Analyze from "./Pages/Analyze";
import Compare from "./Pages/Compare";
import Markup from "./Pages/Markup";
import Waitlist from "./Pages/Waitlist";
import Profile from "./Pages/Profile";
import AssignFilm from "./Pages/AssignFilm";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";

function App() {
    return (
        <AuthProvider>
            <Router>
                <Switch>
                    <Route path="/:path(|desktop-default)">
                        <DesktopDefault {...desktopDefaultData} />
                    </Route>
                    <Route path="/waitlist">
                        <Waitlist />
                    </Route>
                    <Route path="/explore-products">
                        <ExploreProducts />
                    </Route>
                    <Route path="/SwimBot">
                        <SwimBot />
                    </Route>
                    <PrivateRoute path="/manage-teams" component={ManageTeams} />
                    <PrivateRoute path="/my-film" component={MyFilm} />
                    <PrivateRoute path="/all-films" component={AssignFilm} />
                    <PrivateRoute path="/profile" component={Profile} />
                    <PrivateRoute path="/get-started" component={GetStarted} />
                    <PrivateRoute path="/get-started-2/:role" component={GetStarted2} />
                    <PrivateRoute path="/get-started-coach" component={GetStartedCoach} />
                    <PrivateRoute path="/get-started-athlete" component={GetStartedAthlete} />
                    <PrivateRoute path="/coach-profile" component={CoachProfile} />
                    <PrivateRoute path="/analyze" component={Analyze} />
                    <PrivateRoute path="/compare" component={Compare} />
                    <Route path="/markup">
                        <Markup />
                    </Route>
                    <Route path="/sign-up">
                        <SignUp {...signUpData} />
                    </Route>
                    <Route path="/checkout">
                        <Checkout />
                    </Route>
                    <Route path="/checkout-1">
                        <Checkout1
                            logoTransparentWhite1="/img/logo-transparent-white-1@2x.png"
                            orderSummaryProps={checkout1Data.orderSummaryProps}
                            accountSummaryProps={checkout1Data.accountSummaryProps}
                        />
                    </Route>
                    <Route path="/checkout-4">
                        <Checkout4
                            thankYouForYourOrder={
                                <React.Fragment>
                                    Thank you
                                    <br />
                                    for your order!
                                </React.Fragment>
                            }
                            pleaseCheckYourEm={
                                <React.Fragment>
                                    Please check your email inbox for your receipt.
                                    <br />
                                    Order #123456 is on the way.
                                </React.Fragment>
                            }
                            iconTickCircle="/img/---icon--tick-circle-@2x.png"
                            backToProducts="Back to products"
                            logoTransparentWhite1="/img/logo-transparent-white-1@2x.png"
                        />
                    </Route>
                    <Route path="/log-in">
                        <LogIn {...logInData} />
                    </Route>
                    <PrivateRoute path="*" component={() => <Redirect to="/" />} />
                </Switch>
            </Router>
        </AuthProvider>
    );
}

export default App;
const button2Data = {
    className: "button-2",
};

const button22Data = {
    buttonProps: button2Data,
};

const frame7Data = {
    maskGroup: "/img/mask-group-1@2x.png",
    swingbot: "SwingBot",
    button2Props: button22Data,
};

const button3Data = {
    className: "button-3",
};

const button121Data = {
    buttonProps: button3Data,
};

const frame81Data = {
    maskGroup: "/img/mask-group-2@2x.png",
    button1Props: button121Data,
};

const button4Data = {
    className: "button-4",
};

const button122Data = {
    className: "button-2-5",
    buttonProps: button4Data,
};

const frame9Data = {
    maskGroup: "/img/mask-group-3@2x.png",
    swimbot: "SwimBot",
    button1Props: button122Data,
};

const button32Data = {
    children: "Sign up",
};

const desktop2Data = {
    frame7Props: frame7Data,
    frame8Props: frame81Data,
    frame9Props: frame9Data,
    button3Props: button32Data,
};

const button33Data = {
    children: "Sign up",
    className: "button-13",
};

const button43Data = {
    className: "button-19",
};

const frame102Data = {
    className: "frame-10-1",
};

const button7Data = {
    className: "button-6",
};

const button23Data = {
    className: "button-2-2",
    buttonProps: button7Data,
};

const frame721Data = {
    className: "",
    button2Props: button23Data,
};

const button8Data = {
    className: "button-7",
};

const button123Data = {
    buttonProps: button8Data,
};

const frame82Data = {
    maskGroup: "SprintBot",
    className: "frame-12-1",
    button1Props: button123Data,
};

const button9Data = {
    className: "button-8",
};

const button124Data = {
    className: "button-2-7",
    buttonProps: button9Data,
};

const frame921Data = {
    className: "",
    button1Props: button124Data,
};

const carousel1Data = {
    frame10Props: frame102Data,
    frame72Props: frame721Data,
    frame8Props: frame82Data,
    frame92Props: frame921Data,
};

const swingbot1Data = {
    children: "SwingBot",
};

const swingbot2Data = {
    children: "SprintBot",
    className: "sprintbot",
};

const desktopDefaultData = {
    rubiconRoboticsDes: "Rubicon Robotics designs products to bring the highest degree of automation and precision to athletic training, working hand in hand with coaches to help athletes achieve the highest levels of performance.",
    spanText1: "Rubicon",
    spanText2: "  Robotics",
    logoTransparentWhite1: "/img/logo-transparent-white-1@2x.png",
    products: "Products",
    getInTouchWithUs: <React.Fragment>Get in touch<br />with us.</React.Fragment>,
    yourName: "Your name",
    yourEmail: "Your email",
    leaveAMessage: "Leave a message",
    imInterestedIn: "I’m interested in...",
    iAmA: "I am a...",
    button3Props: button33Data,
    button4Props: button43Data,
    carouselProps: carousel1Data,
    swingbot1Props: swingbot1Data,
    swingbot2Props: swingbot2Data,
};

const frame301Data = {
    children: "Last take",
};

const desktop3Data = {
    swimmerSpeed: "Swimmer speed",
    swimmerTempo: "Swimmer tempo",
    timeTracking: "Time tracking",
    surname: "Best trials",
    trialHistory: "Trial history",
    title: "Settings",
    standardFeatures: "Standard  features",
    coachaiFeatures: "CoachAI  features",
    frame30Props: frame301Data,
};

const button52Data = {
    className: "button-23",
};

const button34Data = {
    children: "My Teams",
    className: "button-14",
};

const frame103Data = {
    className: "frame-10-2",
};

const button11Data = {
    className: "button-10",
};

const button24Data = {
    className: "button-2-3",
    buttonProps: button11Data,
};

const frame722Data = {
    className: "frame-11-2",
    button2Props: button24Data,
};

const button15Data = {
    className: "button-11",
};

const button125Data = {
    buttonProps: button15Data,
};

const frame83Data = {
    maskGroup: "SprintBot",
    className: "frame-12-2",
    button1Props: button125Data,
};

const button16Data = {
    className: "button-12",
};

const button126Data = {
    className: "button-2-9",
    buttonProps: button16Data,
};

const frame922Data = {
    className: "frame-13-2",
    button1Props: button126Data,
};

const carousel2Data = {
    frame10Props: frame103Data,
    frame72Props: frame722Data,
    frame8Props: frame83Data,
    frame92Props: frame922Data,
};

const swingbot3Data = {
    children: "SwingBot",
    className: "swingbot-1",
};

const swingbot4Data = {
    children: "SprintBot",
    className: "sprintbot-1",
};

const signbot2Data = {
    className: "signbot-1",
};

const swimbot2Data = {
    className: "swimbot-1",
};

const frame112Data = {
    className: "frame-11-4",
};

const coach2Data = {
    className: "coach-2",
};

const athlete2Data = {
    className: "athlete-2",
};

const other2Data = {
    className: "other-2",
};

const desktopLoggedInData = {
    logoTransparentWhite1: "/img/logo-transparent-white-1@2x.png",
    spanText1: "Rubicon",
    spanText2: "  Robotics",
    products: "Products",
    getInTouchWithUs: <React.Fragment>Get in touch<br />with us.</React.Fragment>,
    yourName: "Your name",
    yourEmail: "Your email",
    leaveAMessage: "Leave a message",
    imInterestedIn: "I’m interested in...",
    iAmA: "I am a...",
    iconProfileCircle: "/img/---icon--profile-circle-@2x.png",
    rubiconRoboticsDes: "Rubicon Robotics designs products to bring the highest degree of automation and precision to athletic training, working hand in hand with coaches to help athletes achieve the highest levels of performance.",
    button5Props: button52Data,
    button3Props: button34Data,
    carouselProps: carousel2Data,
    swingbot1Props: swingbot3Data,
    swingbot2Props: swingbot4Data,
    signbotProps: signbot2Data,
    swimbotProps: swimbot2Data,
    frame11Props: frame112Data,
    coachProps: coach2Data,
    athleteProps: athlete2Data,
    otherProps: other2Data,
};

const frame151Data = {
    children: <React.Fragment>Team 2<br />Name</React.Fragment>,
};

const frame152Data = {
    children: <React.Fragment>Team 3<br />Name</React.Fragment>,
    className: "frame-16",
};

const desktopLoggedIn2Data = {
    iconProfileCircle: "/img/---icon--profile-circle-@2x.png",
    welcomeRubiconadmin1: "Welcome, RubiconAdmin1.",
    number1: "1",
    number2: "9",
    number3: "6",
    team1Name: <React.Fragment>Team 1<br />Name</React.Fragment>,
    number4: "10",
    number5: "11",
    number6: "7",
    number7: "8",
    number8: "13",
    number9: "3",
    selectAnExistingT: "Select an existing team to view your athletes.",
    manageYourTeamsA: <React.Fragment>Manage your teams. <br />Analyze their performance.</React.Fragment>,
    athletes: "Athletes",
    logoTransparentWhite1: "/img/logo-transparent-white-1@2x.png",
    frame151Props: frame151Data,
    frame152Props: frame152Data,
};

const button523Data = {
    className: "button-25",
};

const frame4Data = {
    button5Props: button523Data,
};

const button524Data = {
    className: "button-26",
};

const frame5Data = {
    button5Props: button524Data,
};

const button35Data = {
    children: "Sign up",
    className: "button-15",
};

const button44Data = {
    className: "button-20",
};

const swingbot22Data = {
    logoTransparentWhite1: "/img/logo-transparent-white-1@2x.png",
    earL: "/img/ear-l@2x.png",
    earR: "/img/ear-r@2x.png",
    hands: "/img/hands@2x.png",
    kumisKiri: "/img/kumis-kiri@2x.png",
    kumisKanan: "/img/kumis-kanan@2x.png",
    title: "SwingBot",
    loremIpsumDolorSi: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
    chooseSubscriptionType: "Choose subscription type:",
    place: "Purchase",
    price: "$3000.00",
    number: "1",
    iconMinusCirlce: "/img/---icon--minus-cirlce-@2x.png",
    iconAddCircle: "/img/---icon--add-circle-@2x.png",
    button3Props: button35Data,
    button4Props: button44Data,
};

const frame302Data = {
    children: "Coach AI",
    className: "frame-25",
};

const group422Data = {
    className: "group-41",
};

const button36Data = {
    children: "My Teams",
    className: "button-16",
};

const desktop1Data = {
    spanText1: <React.Fragment>Notes for swimmer:<br /></React.Fragment>,
    spanText2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    iconProfileCircle: "/img/---icon--profile-circle-@2x.png",
    iconEdit: "/img/---icon--edit-@2x.png",
    iconSetting2: "/img/---icon--setting-2-@2x.png",
    swimmerData: "Swimmer Data",
    averageSpeed457Mph: "Average speed: 4.57mph",
    averageTempo457Mph: "Average tempo: 4.57mph",
    surname: "Best trials: 1, 2, 3",
    title: "Analyze",
    markup: "Markup",
    annotate: "Annotate",
    compare: "Compare",
    logoTransparentWhite1: "/img/logo-transparent-white-1@2x.png",
    frame30Props: frame302Data,
    group42Props: group422Data,
    button3Props: button36Data,
};

const button37Data = {
    children: "Sign up",
    className: "button-17",
};

const button45Data = {
    className: "button-21",
};

const swimbot22Data = {
    logoTransparentWhite1: "/img/logo-transparent-white-1@2x.png",
    earL: "/img/ear-l@2x.png",
    earR: "/img/ear-r@2x.png",
    hands: "/img/hands@2x.png",
    kumisKiri: "/img/kumis-kiri-1@2x.png",
    kumisKanan: "/img/kumis-kanan-1@2x.png",
    title: "SwimBot",
    loremIpsumDolorSi: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
    chooseSubscriptionType: "Choose subscription type:",
    place: "Purchase",
    price: "$3000.00",
    number: "1",
    iconMinusCirlce: "/img/---icon--minus-cirlce-@2x.png",
    iconAddCircle: "/img/---icon--add-circle-@2x.png",
    button3Props: button37Data,
    button4Props: button45Data,
};

const group541Data = {
    enterYourUsernameOrEmailAddress: "Enter your username or email address",
    usernameOrEmailAddress: "Username or email address",
};

const group571Data = {
    userName1: "Username",
    userName2: "User name",
};

const group572Data = {
    userName1: "Contact number",
    userName2: "Contact Number",
};

const group542Data = {
    enterYourUsernameOrEmailAddress: "Enter your password",
    usernameOrEmailAddress: "Password",
    className: "group-55",
};

const signUpData = {
    welcomeToRubiconRobotics: "Welcome to Rubicon Robotics!",
    haveAnAccount: "Have an Account?",
    logIn: "Log in",
    title: "Sign up",
    signUp: "Sign up",
    orContinueWith: "or continue with",
    logoTransparentWhite1: "/img/logo-transparent-white-1@2x.png",
    group541Props: group541Data,
    group571Props: group571Data,
    group572Props: group572Data,
    group542Props: group542Data,
};

const orderSummaryData = {
    orderSummary: "Order Summary",
    swimbotPremium: "SwimBot - Premium",
    price1: "$100",
    giftCardDiscountCode: "Gift Card / Discount code",
    subTotal: "Sub total",
    tax: "Tax",
    shipping: "Shipping",
    total: "Total",
    subtractFill1: "",
    number: "1",
    addLine1: "",
    apply: "Apply",
    price2: "$100",
    price3: "$3.45",
    free: "Free",
    price4: "$103.45",
};

const group52Data = {
    className: "group-6",
};

const accountSummaryData = {
    account: "Account",
    shipping: "Shipping",
    payment: "Payment",
    accountDetails: "Account details",
    emailAddress: "Email address",
    emailMyemailCom: "Email@myemail.com",
    checkboxCircleFill2: "",
    password: "Password",
    text13: "********",
    checkboxCircleFill1: "",
    registerForAccount: "Register for account",
    overlapGroup1: "",
    login: "Login",
    line3: "",
    cancelOrder: "Cancel order",
    overlapGroup: "",
    shippingDetails: "Shipping details",
    group5Props: group52Data,
};

const checkout1Data = {
    orderSummaryProps: orderSummaryData,
    accountSummaryProps: accountSummaryData,
};

const group543Data = {
    enterYourUsernameOrEmailAddress: "Enter your username or email address",
    usernameOrEmailAddress: "Username or email address",
    className: "group-54-1",
};

const group544Data = {
    enterYourUsernameOrEmailAddress: "Enter your password",
    usernameOrEmailAddress: "Password",
    className: "group-55-1",
};

const logInData = {
    welcomeToRubiconRobotics: "Welcome to Rubicon Robotics!",
    dontHaveAnAccount: "Don’t have an account?",
    signUp: "Sign up",
    title: "Log in",
    forgotPassword: "Forgot password",
    logIn: "Log In",
    orContinueWith: "or continue with",
    logoTransparentWhite1: "/img/logo-transparent-white-1@2x.png",
    group541Props: group543Data,
    group542Props: group544Data,
};

