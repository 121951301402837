import React, { useState, useEffect, useRef } from "react";
import "./FilmBox.css";

function FilmBox({url, thumbnail, selected, favorited, assignPage, onClick, onAssign, athletes, currentAthleteId}) {

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  const handleMenuClick = (e) => {
    e.stopPropagation(); // Prevent click from triggering parent onClick
    setShowMenu(!showMenu);
  };

  const handleAssignClick = (athleteId) => {
    onAssign(athleteId);
    setShowMenu(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className="film-box" onClick={onClick}>
        {favorited && !assignPage && (
          <img id="favorited-vid" src={require("../../static/img/heart-icon.png")} alt="Favorited heart icon" />
        )}
          <div className={selected ? 'thumbnail selected' : 'thumbnail'}>
            <img src={thumbnail} alt="Video Thumbnail" />
            {assignPage && (
              <div className="thumbnail-menu" onClick={handleMenuClick}>
                {Object.keys(athletes).length > 1 && (
                  <span className="three-dots">...</span>
                )}
                {showMenu && (
                  <div className="menu-popup" ref={menuRef}>
                    <ul>
                    {currentAthleteId !== 'Unassigned Videos' && (
                      <li key='unassign' onClick={() => handleAssignClick('Unassigned Videos')}>
                        Unassign Film
                      </li>
                    )}
                    {Object.entries(athletes).map(([athleteId, athleteDetails]) => {
                      if (athleteId === currentAthleteId) {
                        return null; // Skip the current athlete
                      }
                      return (
                        <li key={athleteId} onClick={() => handleAssignClick(athleteId)}>
                          <img id="assign-icon" src={require("../../static/img/assign_icon.png")} alt="Assign to" /> {athleteDetails[0]}
                        </li>
                      );
                    })}
                    </ul>
                  </div>
                )}
              </div>
            )}
        </div>
    </div>    
  );
}

export default FilmBox;
