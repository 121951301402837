import React, { useState } from "react";
import "./Waitlist.css";

function Waitlist() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    function handleSubmit() {
        const data = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "join_date": new Date().toISOString().split('T')[0]
        };

        // Clear input fields
        setFirstName('');
        setLastName('');
        setEmail('');

        fetch(BASE_URL + '/api/join_waitlist/', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
          console.log("Joined successfully!");
        })
        .catch(error => {
            alert(error)
            console.error('Error joining waitlist: ', error);
        });
    };

    return (
        <div className="background">
            <div className="header-text">Join the waitlist!</div>

            {/* Waitlist Form */}
            <div className="waitlist-form">
                <input
                    type="name"
                    className="form-control mt-1 contact-form-input"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => {setFirstName(e.target.value);}}
                />
                <input
                    type="name"
                    className="form-control mt-1 contact-form-input"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => {setLastName(e.target.value);}}
                />
                <input
                    type="email"
                    className="form-control mt-1 contact-form-input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value);}}
                />
                <button
                    className="submit"
                    onClick={handleSubmit}
                    >
                    Join
                </button>
            </div>
        </div>
    );
}

export default Waitlist;
