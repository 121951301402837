import React, { useEffect, useState } from "react";
import "./Analyze.css";
import HomeLogo from "../../components/HomeLogo";
import ProfileIcon from "../../components/ProfileIcon";
import {useHistory, useLocation, Redirect} from 'react-router-dom';
import FilmBox from "../../components/FilmBox";
import RoundedButton from "../../components/RoundedButton";
import HeaderMotto from "../../components/HeaderMotto";
import Plot from 'react-plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { BASE_URL } from "../../constants/company-info";

function Analyze() {
  const Plotly = require('plotly.js-basic-dist');
  const Plot = createPlotlyComponent(Plotly);

  const location = useLocation();
  const athleteId = location.state?.athleteId;
  const isCoach_str = localStorage.getItem('is_coach');

  if (!athleteId) {
    if (isCoach_str === 'false') {
      return <Redirect to='/my-film' />
    }
    else {
      return <Redirect to='/manage-teams' />
    }
  };

  const athleteName = location.state?.athleteName;
  const userId = location.state?.userId;
  const name = location.state?.name;
  const videoId = location.state?.videoId;
  const coachName = location.state?.coachName;
  const selectedVideoIndex = location.state?.videoIndex;
  const isCoach = location.state?.isCoach;

  const history = useHistory();

  const [films, setFilms] = useState([]);
  const [videoIndex, setVideoIndex] = useState(0);
  const [featuredVideoId, setFeaturedVideoId] = useState(0);

  const [favorited, setFavorited] = useState([]); // list of video ids
  const [notes, setNotes] = useState("");
  const [text, setText] = useState(notes);
  const [coachAIVidURL, setCoachAIVidURL] = useState(null)

  // Filler data for graphs
  const speed = [4.5,4.6,4.6,4.4,4.8,5.0,4.8,4.8,4.7,4.8,4.8];
  const distance = [5,10,15,25,30,35,40,45,50,55,60];
  const speedDistanceData = [{
    x: distance,
    y: speed,
    mode:"markers",
    type:"scatter",
    marker: {
      color: '#ffffff'
    }
  }];

  const speedDistanceLayout = {
    xaxis: {range: [0,65], title: "Distance (m)", color: '#ffffff', showgrid: false},
    yaxis: {range: [4.3, 5.2], title: "Speed (mph)", color: '#ffffff', showgrid: false},
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    shapes: [
      {
        type: 'line',
        x0: 0,
        y0: 4.3,
        x1: 65,
        y1: 4.3,
        line: {
          color: 'white',
          width: 2
        }
      }
    ],
    margin: { t: 30 }
  };
  
  const handleSaveText = (event) => {
    setNotes(text);

    // Save to database
    const data = {
      "video": featuredVideoId,
      "notes": text,
    };
  
    fetch(BASE_URL + '/api/coach_notes/', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
          console.log("successfully saved notes!")

          const notificationData = {
              "message": "New notes have been added to your video",
              "user_id": athleteId,  // Replace athleteID with the actual ID
              "redirect_url": "URL to the relevant video or notes page"  // Optional, adjust as needed
          };

            return fetch(BASE_URL + '/api/add_notifications/', {  // Call your add notification API endpoint
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(notificationData),
            });
          })
          .then(response => response.json())
          .then(data => {
            console.log("Successfully added notification for the athlete!");


        })
        .catch(error => {
            console.error('Error saving notes: ', error);
        });
  };

  const handleNotesChange = (event) => {
    setText(event.target.value);
  };

  useEffect (() => {
    // Retrieve videos for athlete
    fetch(BASE_URL + '/api/get_film?athleteUserId='+athleteId, {
        method: "GET",
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        setFilms(data);
        if (videoId) {
          setFeaturedVideoId(videoId);
          setVideoIndex(selectedVideoIndex);

          // Retrieve coach notes for the video
          fetch(BASE_URL + '/api/coach_notes?videoId='+videoId, {
            method: "GET",
            credentials: 'include'
          })
          .then(response => response.json())
          .then(data => {
            if (data) {
              setText(data);
              setNotes(data);
            }
          })
          .catch(error => {
              console.error('Error getting notes: ', error);
          });
        }
        else if (!videoId && data.length) {
          setFeaturedVideoId(data[0][2])
          // Retrieve coach notes for the video
          fetch(BASE_URL + '/api/coach_notes?videoId='+data[0][2], {
            method: "GET",
            credentials: 'include'
          })
          .then(response => response.json())
          .then(data => {
            if (data) {
              setText(data);
              setNotes(data);
            }
          })
          .catch(error => {
              console.error('Error getting notes: ', error);
          });
        }
      })
      .catch(error => {
          console.error('Error getting athlete videos: ', error);
      });

      // Retrieve favorited videos for this coach and athlete
      fetch(BASE_URL + '/api/favorite?userId='+userId, {
        method: "GET",
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        setFavorited(data);
      })
      .catch(error => {
          console.error('Error getting favorited videos: ', error);
      });
  }, []);

  const handleSelectFilm = (index, videoId) => {
    setVideoIndex(index);
    setFeaturedVideoId(videoId);

    // Retrieve coach notes
    fetch(BASE_URL + '/api/coach_notes?videoId='+videoId, {
      method: "GET",
      credentials: 'include'
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        setText(data);
        setNotes(data);
      }
      else {
        setText("");
        setNotes("");
      }
    })
    .catch(error => {
        console.error('Error getting notes: ', error);
    });
  }

  // Handles favoriting & unfavoriting
  const handleFavorite = () => {
    const isCurrentlyFavorited = favorited.includes(featuredVideoId);

    const method = isCurrentlyFavorited ? 'DELETE' : 'POST';

    const data = {
      "video": featuredVideoId,
      "user": userId,
    };

  
    fetch(BASE_URL + '/api/favorite/', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
          setFavorited([...favorited, featuredVideoId]);
        })
        .catch(error => {
            console.error('Error favoriting video: ', error);
        });
  }

  const handleCompare = () => {
    
    history.push({
      pathname: '/compare', // The target route
      state: {
        athleteName: athleteName,
        athleteId: athleteId,
        userId: userId,
        coachName: name,
        currVideo: films[videoIndex][0]
      }
    });
  };

  const handleMyTeamsClick = () => {
    history.push({
      pathname: `/manage-teams/`,
      state: { userId: userId,
        name: coachName }
    });
  }

  const handleCoachAI = (event) => {
    setCoachAIVidURL("https://swimbot-demo-videos.s3.amazonaws.com/result.mp4")
  }

  const handleCloseCoachAI = () => {
    setCoachAIVidURL(null)
  }

  return (
      <div className="analyze-page">
        <HomeLogo/>
        <HeaderMotto/>
        {isCoach && (
            <div className="myTeams-button-coach-profile" onClick={() => handleMyTeamsClick()}>
              My Teams
            </div>
        )}
    <ProfileIcon userId={userId}/>

  <div className="analyze-page-content">
    <div className="content-container">
          {/* Left Part of Page */}
          <div className="left-section">
            <div className="analyze-header">
              <div className="analyze-title">Analyze - {athleteName}</div>
              {films.length > 0 && (
                <div className="rounded-buttons">
                  <button onClick={() => console.log("Markup clicked")} className="select-button"> Markup </button>
                  <button onClick={() => handleCompare()} className="select-button"> Compare </button>
                  <button onClick={handleCoachAI} className="select-button"> Coach AI</button>
                  <button onClick={() => console.log("Share clicked")} className="select-button"> Share </button>
                  <button onClick={() => handleFavorite()} className={favorited.includes(films[videoIndex][2]) ? "favorited" : "not-fav"} >
                    {favorited.includes(films[videoIndex][2]) ? "Favorited!" : "Favorite"}
                  </button>
                </div>
              )}
              
              {films.length > 0 && (
                <div>
                  <iframe
                    title="Swimmer Video"
                    width="100%"
                    height="100%"
                    src={films[videoIndex][0]}
                    allowFullScreen
                  ></iframe>
                </div>
              )}
              <div className="analyze-coach-notes">Coach's Notes</div>
              <div className="coach-notes-container">
                <textarea
                  className="coach-notes-textbox"
                  value={text}
                  placeholder='Add Comments...'
                  onChange={handleNotesChange}
                  disabled={!isCoach}
                />
                {text !== notes && (<RoundedButton label='Save' onClick={handleSaveText} />)}
              </div>
            </div>
          </div>

          {/* Right Part of Page */}
          <div className="right-section">
              <div className="swimmer-data-header">
                <div className="swimmer-data-title">Swimmer Data</div>
                <div className="swimmer-data">
                  <div className="swimmer-data-box">
                    <Plot
                      data={speedDistanceData}
                      layout={speedDistanceLayout}
                      useResizeHandler={true}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              </div>
          </div>
        </div>

        <div className="films">
          <div className="athletes-film">Swimmer Film</div>
          {films && (
            <div className="films-grid">
              {films.length > 0 && films.map((film, index) => (
                <FilmBox 
                  key={film[0]}
                  url={film[0]}
                  thumbnail={film[1]}
                  selected={index==videoIndex}
                  favorited={favorited.includes(films[index][2])}
                  onClick={() => handleSelectFilm(index, film[2])}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      {coachAIVidURL && (
          <div className="video-overlay">
              <iframe
                  title="Swimmer Video"
                  src={coachAIVidURL}
                  allowFullScreen
              />
              <RoundedButton label='❌' onClick={handleCloseCoachAI} />
          </div>
      )}

    </div>
  );
}

export default Analyze;
