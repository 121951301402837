import React from "react";
import "./Group5.css";

function Group5(props) {
  const { className } = props;

  return (
    <div className={`group-5-1 ${className || ""}`}>
      <img className="line-5-1" src="" alt="Line 5" />
      <img className="checkbox-circle-fill-3" src="" alt="checkbox-circle-fill 3" />
      <img className="line-6-1" src="" alt="Line 6" />
    </div>
  );
}

export default Group5;
