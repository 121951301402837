import React from "react";
import { Link } from "react-router-dom";
import OrderSummary from "../OrderSummary";
import AccountSummary from "../AccountSummary";
import "./Checkout1.css";

function Checkout1(props) {
  const { logoTransparentWhite1, orderSummaryProps, accountSummaryProps } = props;

  return (
    <div className="container-center-horizontal">
      <div className="checkout-1 screen">
        <div className="overlap-group5-1">
          <div className="ellipse-2-9"></div>
          <div className="ellipse-1-7"></div>
          <div className="rectangle-90"></div>
          <div className="rectangle-91"></div>
          <OrderSummary {...orderSummaryProps} />
          <AccountSummary {...accountSummaryProps} />
          <Link to="/desktop-default">
            <img className="logo-transparent-white-1-8" src={logoTransparentWhite1} alt="logo-transparent-white 1" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Checkout1;
