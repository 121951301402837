import React from "react";
import "./ExploreProductsButton.css";



function ExploreProductsButton() {

  return (
    <a href="/waitlist" className="explore-products-link">
        <div className="explore-products-button">
            Join our Waitlist! <img class="forward-img" src={require("../../static/img/forward-9.svg")} alt="Forward"></img>
        </div>
    </a>
  );
}

export default ExploreProductsButton;
