import React from "react";
import "./ExploreProducts.css";
import ProductBox from "../../components/ProductBox";
import HomeLogo from "../../components/HomeLogo";
import SignUpLogInButtonCluster from "../../components/SignUpLogInButtonCluster"

function ExploreProducts() {

  return (
    <div className="explore-products-page">
        <HomeLogo/>
        <SignUpLogInButtonCluster/>
        <div className="our-products">
            Our Products
        </div>
        <div className="product-container">
            <div className="swimbot-box"> 
                <ProductBox
                    img = "/img/mask-group@2x.png"
                    title = "SwimBot"
                    buttoncolor = "linear-gradient(#308eac 0%, #376397 100%)"
                    buttontext = "Learn more"
                    link = '/swimbot'
                    pageclassname = "explore-page-product-box"
                />
            </div>
            <div className="swingbot-box"> 
                <ProductBox
                    img = "/img/mask-group-1@2x.png"
                    title = "SwingBot"
                    buttoncolor = "linear-gradient(#60b177 0%, #147747 100%)"
                    buttontext = "Learn more"
                    link = 'swingbot'
                />
            </div>
            <div className="sprintbot-box"> 
                <ProductBox
                    img = "/img/mask-group-2@2x.png"
                    title = "SprintBot"
                    buttoncolor = "linear-gradient(#b072d6 0%, #7433a8 100%)"
                    buttontext = "Learn more"
                    link = 'sprintbot'
                />
            </div>
        </div>
    </div>
  );
}

export default ExploreProducts;
