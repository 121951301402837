import React, { useEffect, useState } from 'react';
import AthleteBox from "../AthleteBox";
import "./AthleteDisplay.css";
import { BASE_URL } from "../../constants/company-info";

function AthleteDisplay({teamId, coachUserId, onSelectAthlete}) {

    const [athletes, setAthletes] = useState([]);

    useEffect(() => {
        // get athletes
        const url = new URL(BASE_URL + '/api/get_athletes');
        url.search = new URLSearchParams({
            team_id: teamId,
            coach_user_id: coachUserId
        }).toString();

        fetch(url, {
            method: "GET",
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            setAthletes(data)
        })
        .catch(error => {
            console.error('Error getting athletes: ', error);
        });
    }, []);

    const handleSelectAthlete = (athleteName, athleteUserId) => { // athlete user id
        if(onSelectAthlete) {
            onSelectAthlete(athleteName, athleteUserId);
        }
    }

    return(
        <div className="athlete-grid">
            {athletes.map((athlete) => (
                <AthleteBox
                    key={athlete.user_id}
                    name={athlete.name}
                    athlete_id={athlete.athlete_id}
                    profile_picture={athlete.profile_picture}
                    onClick={() => handleSelectAthlete(athlete.name, athlete.user_id)} // Add onClick to handle athlete selection
                />
            ))}
        </div>
    );
}

export default AthleteDisplay;
