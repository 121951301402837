import React from "react";
import "./LogInTemplate.css";

function LogInTemplate(props) {
const { needOrHaveAnAccount, signUpOrLogIn, title, form} = props

  return (
    <div className="login-template">
        <div className='welcome'>Welcome to Rubicon Robotics!</div>
        <div className='need-or-have-an-account'>{needOrHaveAnAccount}<br /> {signUpOrLogIn}</div>
        <div className="log-sign-title"><h1>{title}</h1></div>
        {form}
        <div className="continue-with">
            <p className="continue-with-text">or continue with</p>
            <div className="other-account-buttons">
                <img className="facebook" src={require("../../static/img/facebook.svg")} alt="Facebook" />
                <img className="apple" src={require("../../static/img/apple.svg")} alt="apple" />
                <img className="google" src={require("../../static/img/google.svg")} alt="google" />
            </div>
        </div>
    </div>
  );
}

export default LogInTemplate;
