import React, { useState } from "react";
import SignUpLogInButtonCluster from "../../components/SignUpLogInButtonCluster";
import HomeLogo from "../../components/HomeLogo";
import HomeHeader from "../../components/HomeHeader";
import ProductCarousel from "../../components/ProductCarousel";
import ExploreProductsButton from "../../components/ExploreProductsButton";
import ContactForm from "../../components/ContactForm";
import "./DesktopDefault.css";
import FullHomeHeader from "../../components/FullHomeHeader";


function DesktopDefault(props) {
  const {
    rubiconRoboticsDes,
    spanText1,
    spanText2,
    logoTransparentWhite1,
    products,
    getInTouchWithUs,
    // yourName,
    yourEmail,
    leaveAMessage,
    imInterestedIn,
    iAmA,
    button3Props,
    button4Props,
    carouselProps,
    swingbot1Props,
    swingbot2Props,
  } = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [role, setRole] = useState(null);
  const [interest, setInterest] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Message received');
  };

  const handleClick = () => {
  alert('Order received'); // Display the alert
  window.location.href = '/desktop-default';
};

  const yourName = 'MarkKirichev';

  return (
    <div className="home-page">
      <div className="main-home-section">
        <div className="top-bar">
          <FullHomeHeader/>
        </div>
        <HomeHeader/>
        <ExploreProductsButton/>
      </div>
      <div className="products-section">
        <div className="products-text">
          Products
        </div>
        <ProductCarousel/>
      </div>
      <div className="contact-home-section">
          <div className="contact-us-text">
            Get in touch <br className="contact-linebreak"/>
            with us. <br/>
            <img id="contact-us-arrow" src={require("../../static/img/arrow-1.svg")} alt="Arrow 1"></img>
          </div>
          <ContactForm/>
      </div>
      
      
    </div>
  );
}

export default DesktopDefault;
