import React from "react";
import "./AthleteBox.css";

function AthleteBox({name, athlete_id, profile_picture, onClick}) {

  return (
    <div className="athlete-box" onClick={onClick}>
        <img className="profile-pic" src={profile_picture} alt="Profile Picture" />
        <div className="athlete-label">
            {name}
        </div>
    </div>    
  );
}

export default AthleteBox;
