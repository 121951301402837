import React from "react";
import "./OrderSummary.css";

function OrderSummary(props) {
  const {
    orderSummary,
    swimbotPremium,
    price1,
    giftCardDiscountCode,
    subTotal,
    tax,
    shipping,
    total,
    subtractFill1,
    number,
    addLine1,
    apply,
    price2,
    price3,
    free,
    price4,
  } = props;

  return (
    <div className="order-summary">
      <div className="flex-col">
        <div className="order-summary-1 neurialgrotesk-medium-black-20px">{orderSummary}</div>
        <div className="replace-photo"></div>
      </div>
      <div className="flex-row-3">
        <div className="flex-col-1">
          <div className="swim-bot-premium neurialgrotesk-medium-black-20px">{swimbotPremium}</div>
          <div className="price-2">{price1}</div>
          <p className="gift-card-discount-code neurialgrotesk-medium-slate-gray-16px">{giftCardDiscountCode}</p>
          <div className="rectangle-8"></div>
          <div className="sub-total neurialgrotesk-medium-fiord-18px">{subTotal}</div>
          <div className="flex-col-item neurialgrotesk-medium-fiord-18px">{tax}</div>
          <div className="flex-col-item neurialgrotesk-medium-fiord-18px">{shipping}</div>
          <div className="total">{total}</div>
        </div>
        <div className="flex-col-2">
          <div className="flex-row-4">
            <div className="overlap-group1-8">
              <img className="subtract-fill-1" src={subtractFill1} alt="subtract-fill 1" />
            </div>
            <div className="number-16 neurialgrotesk-medium-black-20px">{number}</div>
            <div className="overlap-group2-7">
              <img className="add-line-1" src={addLine1} alt="add-line 1" />
            </div>
          </div>
          <div className="overlap-group-13">
            <div className="apply button-medium">{apply}</div>
          </div>
          <div className="price-3 neurialgrotesk-medium-outer-space-20px">{price2}</div>
          <div className="price-4 neurialgrotesk-medium-outer-space-20px">{price3}</div>
          <div className="free">{free}</div>
          <div className="price-5 neurialgrotesk-medium-outer-space-20px">{price4}</div>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
