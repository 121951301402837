import React, {useState, useRef, useEffect} from "react";
import {Redirect, useLocation, useHistory} from "react-router-dom";
import "./CoachProfile.css";
import HomeLogo from "../../components/HomeLogo";
import LogInTemplate from "../../components/LogInTemplate";
import HeaderMotto from "../../components/HeaderMotto";
import ProfileIcon from "../../components/ProfileIcon";
import { BASE_URL } from "../../constants/company-info";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";


function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}

async function getCsrfToken() {
  try {
      const dummyResponse = await fetch(BASE_URL + '/api/csrf_cookie', {
          credentials: 'include',
      });
      if (!dummyResponse.ok) {
          console.error('HTTP Error setting CSRF cookie');
          return null;
      }
      const csrfToken = getCookie('csrftoken');
      console.log('CSRF Token:', csrfToken);
      return csrfToken;
  } catch (error) {
      console.error('There was a problem with your fetch csrf_cookie operation:', error);
      return null;
  }
}

async function fetchDataWithToken(userId, BASE_URL, setUsername, setEmail, setPassword) {
    try {
        // GET request to retrieve CSRF token
        const csrfToken = await getCsrfToken();
        if (!csrfToken) {
            console.error('Failed to retrieve CSRF token.');
            return;
        }
        console.log("arrived");

        // Fetch request that requires CSRF token
        const response = await fetch(BASE_URL + '/api/get_username?user_id=' + encodeURIComponent(userId), {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,  // Include the CSRF token in the request headers
            },
            credentials: 'include'
        });

        const data = await response.json();
        console.log('API Response:', data); // Log the entire response

        if (response.ok) {
            setUsername(data[0]);  // Adjust this based on the actual structure of `data`
            setEmail(data[2]);
            console.log('Username:', data);
        } else {
            console.error('Failed to fetch username:', data.error || 'Unknown error');
        }


    } catch (error) {
        console.error('Error during fetch:', error);
    }
}



function CoachProfile() {
    const location = useLocation();
    const userId = location.state?.userId || localStorage.getItem('userId');
    const isCoach = localStorage.getItem('is_coach');
    const [coachId, setCoachId] = useState("");
    const [coachName, setCoachName] = useState("");
    const [originalCoachName, setOriginalCoachName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('********');
    const [email, setEmail] = useState('');
    const [newTeamName, setNewTeamName] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [originalPhone, setOriginalPhone] = useState("");
    const [originalAddress, setOriginalAddress] = useState("");
    const [originalEmail, setOriginalEmail] = useState("");
    const [originalUsername, setOriginalUsername] = useState("");
    const [haveSubs, setHaveSubs] = useState(false);
    const [haveBots, setHaveBots] = useState(false);

    const [teams, setTeams] = useState([]);








    const history = useHistory();




    if (isCoach === 'false') {
      return <Redirect to='/my-film' />
    };


    useEffect(() => {
        if (userId) {
            fetchDataWithToken(userId, BASE_URL, setUsername, setEmail, setPassword)
                .then(() => {
                    // Optional: You can handle any follow-up actions here
                })
                .catch(error => {
                    console.error('Error handling fetchDataWithToken:', error);
                });
        }
    }, [userId, BASE_URL]);
  
    // Get coach-id
  useEffect (() => {
    // Get athlete Id
    fetch(BASE_URL + '/api/get_coachid?user_id='+userId, {
        method: "GET",
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        setCoachId(data[0])
        setCoachName(data[1])
      })
      .catch(error => {
          console.error('Error getting coach id: ', error);
      });

    //get_phone number
    fetch(BASE_URL + '/api/get_phone_add?user_id='+userId, {
      method: 'GET',
      credentials: 'include'
    })
    .then(response => response.json())
    .then(data => {
      if (data[0]) {
        console.log(data);
        setPhoneNumber(data[0]);
        setAddress(data[1]);

      } else if (data.error) {
        console.error('Error getting phone number: ', data.error);
      }
    })
    .catch(error => {
      console.error('Error getting phone number: ', error);
    });

    //get_teams. This method does not work correctly if coach has multiple teams.
    fetch(BASE_URL + '/api/get_teams?user_id='+userId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
            console.log("Teams are: ", data);
            setTeams(data);
        } else {
          console.error('Received data is not an array:', data);
          setTeams([]);
        }
      })
      .catch(error => {
          console.error('Error getting teams: ', error);
      });
  }, []);


    const profileInfoRef = useRef(null);
    const teamsRef = useRef(null);
    const subscriptionsRef = useRef(null);

    const handleCreateTeam = async () => {
        let teamId = "";

        teamId = await createTeam(newTeamName);
        await addTeamToCoach(userId, teamId);
        setIsEditing(false);
    };

    const addTeamToCoach = async (coachId, teamId) => {
        const data = {
          "coach_id": coachId,
          "team_id": teamId
        };

        try {
          const response = await fetch(BASE_URL + '/api/add_team_to_coach/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
        } catch (error) {
          console.error('Error associating team and coach: ', error);
          return null;
        }
    };

    const createTeam = async (teamName) => {
        const data = {
          "name": teamName,
          "description": "filler team description",
          "sport": 1
        };

        try {
          const response = await fetch(BASE_URL + '/api/teams/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
          const jsonData = await response.json();
          return jsonData["id"];
        } catch (error) {
          alert(error);
          console.error('Error retrieving team ID: ', error);
          return null;
        }
    }



  // Function to scroll to the respective sections within the profile-info div
  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleMyTeamsClick = () => {
    history.push({
      pathname: `/manage-teams/`,
      state: { userId: userId,
      name: coachName }
    });
  }

  const handleSubscribe = () =>{
      history.push({
          pathname: '/swimbot'
      })
  }

    const handleEdit = () => {
    // Set the original name before entering editing mode
        setOriginalCoachName(coachName);
        setOriginalPhone(phoneNumber);
        setOriginalAddress(address);
        setOriginalEmail(email);
        setOriginalUsername(username);
        setIsEditing(true);
    };
    const handleSave = () => {
      //name fixed
      /*
      const [firstName, lastName] = coachName.split(" ");
      const updatedData = {
      "user_id": userId,
      "first_name": firstName,
      "last_name": lastName
      };
      const updatePhone = {
          "user_id": userId,
          "new_phone_number": phoneNumber
      };

      const updatedUsernameEmail = {
      "user_id": userId,
      "username": username,
      "email": email
      };

      //address
      const updateAddress = {
          "user_id": userId,
          "new_address": address
      };
      */
         // Split coachName into firstName and lastName if coachName is not empty
      const [firstName, lastName] = coachName
        ? coachName.split(" ")
        : (originalCoachName ? originalCoachName.split(" ") : ["", ""]);

      const updatedData = {
        "user_id": userId,
        "first_name": firstName || "",
        "last_name": lastName || ""
      };


      const updatePhone = {
        "user_id": userId,
        "new_phone_number": phoneNumber || originalPhone
      };

      const updatedUsernameEmail = {
        "user_id": userId,
        "username": username || originalUsername,
        "email": email || originalEmail
      };

      const updateAddress = {
        "user_id": userId,
        "new_address": address || originalAddress
      };



      // Send the updated username/email to the server
      fetch(`${BASE_URL}/api/update_username_email/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUsernameEmail),
      })


      // Send the updated name to the server
      fetch(`${BASE_URL}/api/update_name/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      })

      //Email

      //Phone Number
      fetch(`${BASE_URL}/api/update_phone/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatePhone),
      })


      fetch(`${BASE_URL}/api/update_address/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateAddress),
      })
      setIsEditing(false);
      setCoachName(coachName || originalCoachName);
      setPhoneNumber(phoneNumber || originalPhone);
      setAddress(address || originalAddress);
      setEmail(email || originalEmail);
      setUsername(username || originalUsername);

    };


  const handleCancel = () => {
    // Implement cancel functionality here
    setCoachName(originalCoachName);
    setPhoneNumber(originalPhone);
    setAddress(originalAddress);
    setEmail(originalEmail);
    setUsername(originalUsername);
    setIsEditing(false);
  };

  return (
      <div className="coach-profile-page">
        <HomeLogo/>
        <HeaderMotto/>
        <ProfileIcon userId={userId} />
        <div className="myTeams-button-coach-profile" onClick={() => handleMyTeamsClick()}>
            My Teams
        </div>
        

        <div className="coach-text">
            Coach Profile
        </div>
      <div className="button-box">
        <div className="button-element" onClick={() => scrollToSection(profileInfoRef)}>
          Personal Information
        </div>
        <div className="border"></div>
        <div className="button-element" onClick={() => scrollToSection(teamsRef)}>
          Teams
        </div>
        <div className="border"></div>
        <div className="button-element" onClick={() => scrollToSection(subscriptionsRef)}>
          Subscriptions
        </div>
      </div>
            {isEditing && (
              <div className="save-cancel-buttons">
                <div className="save-button" onClick={() => handleSave()}>
                  Save
                </div>
                <div className="cancel-button" onClick={() => handleCancel()}>
                  Cancel
                </div>
              </div>
            )}

          <div className="profile-info" >
              <div className="grid-item coach-name" >
                  Coach {coachName}
                  <br/>
                  Swimming
              </div>
            <div className="grid-item profile-loc">
                <img
                src={require("../../static/img/---icon--profile-circle-@2x.png")}
                alt="icon profile circle"
                />
            </div>
            <div className="grid-item edit-profile" ref={profileInfoRef}>
                <div className="editable-text" onClick={() => handleEdit()}>
                    Edit Profile
                </div>
            </div>

            <div className="grid-item personal-text">
                Personal Information
            </div>

            <div className="grid-item small-name">
                Name
            </div>

            <div className="grid-item small-username">
                Username
            </div>

            <div className="grid-item fill-name">
                 {isEditing ? (
                      <input
                        type="text"
                        value={coachName}
                        onChange={(e) => setCoachName(e.target.value)}
                        className="edit-coach-name-input"
                      />
                      ) : (
                      `Coach ${coachName}`
                 )}

            </div>
            <div className="grid-item gap">

            </div>
            <div className="grid-item fill-username">
                {isEditing ? (
                      <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="edit-coach-name-input"
                      />
                      ) : (
                      `${username}`
                 )}
            </div>

            <div className="grid-item small-name">
                Email
            </div>

            <div className="grid-item small-username">
                Password
            </div>

            <div className="grid-item fill-name">
                {isEditing ? (
                      <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="edit-coach-name-input"
                      />
                      ) : (
                      `${email}`
                 )}

            </div>
              <div className="grid-item gap">

            </div>
            <div className="grid-item fill-username">
                {password}
            </div>


            <div className="grid-item small-name">
                Phone
            </div>
            <div className="grid-item small-username">
                Address
            </div>
            <div className={`grid-item phone ${!phoneNumber ? 'no-border' : ''}`}>
                {isEditing ? (
                      <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="edit-coach-name-input"
                      />
                      ) : (
                      `${phoneNumber}`
                 )}
                  {!phoneNumber && !isEditing && (
                    <div className="grid-item get-subs" onClick={() => handleEdit()}>
                        Add Phone Number
                    </div>
                  )}
            </div>

            <div className={`grid-item address ${!address ? 'no-border' : ''}`}>

                    {isEditing ? (
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="edit-coach-name-input"
                      />
                      ) : (
                      `${address}`
                 )}
                {!address && !isEditing && (
                    <div className="grid-item get-subs" onClick={() => handleEdit()}>
                        Add Address
                    </div>
                  )}
            </div>


           <div ref={teamsRef} className="grid-item Teams">
               Teams
           </div>

            {teams.length === 0 ? (null) : (


               teams.map((team, index) => (
                    <>

                        <div className="grid-item list1">
                            {team.name}
                        </div>

                        <div className="grid-item list1">
                            {team.sport === 1 ? 'Swimming' : 'Other Sport'}
                        </div>

                        <div className="grid-item list-end">
                            {isEditing ? "Delete Team" : "Owner"}
                        </div>
                    </>
                ))
            )}

            {isEditing && (
              <>
                <div className="grid-item new-team">
                  Create a New Team:
                </div>
                <div className="grid-item new-team">
                  <input
                    type="text"
                    value={newTeamName}
                    onChange={(e) => setNewTeamName(e.target.value)}
                    className="edit-coach-name-input"
                    placeholder="Team Name"
                  />

                </div>
                <div className = "grid-item create-team" onClick={() => handleCreateTeam()}>
                    Create
                </div>
              </>
            )}


            <div ref={subscriptionsRef} className="grid-item Teams">
                Subscriptions
            </div>
              {haveSubs && (
                  <>
                      <div className="grid-item list1">
                        type (bot)
                    </div>

                    <div className="grid-item list1">
                        Type (standard)
                    </div>

                    <div className="grid-item list-end">
                        N users
                    </div>
                  </>
              )}
              {!haveSubs && (
                  <div className = "grid-item get-subs" onClick={() => handleSubscribe()}>
                      Subscribe
                  </div>
              )}


            <div className="grid-item Teams">
                Bots
            </div>

              {haveBots && (
                  <>
                      <div className="grid-item list1">
                        SwimBot
                    </div>

                    <div className="grid-item list1">
                        ID #
                    </div>

                    <div className="grid-item list-end">
                        Type (olympic/standard)
                    </div>
                  </>
              )}
              {!haveBots && (
                  <div className = "grid-item get-subs" onClick={() => handleSubscribe()}>
                      Purchase a Bot
                  </div>
              )}
















          </div>
    </div>
  );
}

export default CoachProfile;
