import React, { useState } from "react";
import ImageCarousel from "../ImageCarousel";
import "./PurchaseProductTemplate.css";

function PurchaseProductTemplate(props) {
    const { productname, productimages, description, description2, features} = props;
    const [quantity, setQuantity] = useState(1);
    const [hardware, setHardware] = useState("");
    const [leaseOrPurchase, setLeaseOrPurchase] = useState("");
    const [softwareSubscription, setSoftwareSubscription] = useState("");
    const [numAthletes, setNumAthletes] = useState("");
    const [filmRetention, setFilmRetention] = useState("");
    const [downloadPermissions, setDownloadPermissions] = useState("");
    const [purchaseCompleted, setPurchaseCompleted] = useState(false);
    const isSoftwareSelected = softwareSubscription !== "";
    const isNumAthletesSelected = numAthletes !== "";
    const isFilmRetentionSelected = filmRetention !== "";
    const isTrial = softwareSubscription === "Trial";

    const isPurchaseEnabled = () => {
        return hardware && leaseOrPurchase && softwareSubscription && numAthletes && filmRetention && downloadPermissions;
    };
    const handlePurchaseClick = () => {
        if (isPurchaseEnabled()) {
            setPurchaseCompleted(true);
        }
    };

    const handleIncrease = () => {setQuantity(prevQuantity => prevQuantity + 1);};
    const handleDecrease = () => {setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 1));};

        const calculateUpfront = () => {
    let upfront = 0;

    // Lease/Purchase prices
    if (hardware === "Premium"  && leaseOrPurchase === "Purchase") upfront = 12000;
    if (hardware === "Basic"  && leaseOrPurchase === "Purchase") upfront = 10000;


    return upfront * quantity;
  };

     const calculateTotalPrice = () => {
    let totalPrice = 0;

    if (hardware === "Premium"  && leaseOrPurchase === "Lease") totalPrice +=600;
    if (hardware === "Basic"  && leaseOrPurchase === "Lease") totalPrice += 400;

    // Software subscription prices
    if (softwareSubscription === "Basic") totalPrice += 10;
    if (softwareSubscription === "Standard") totalPrice += 75;
    if (softwareSubscription === "Professional") totalPrice += 275;
    if (softwareSubscription === "Olympic") totalPrice += 350;

    if (numAthletes === "26-50") totalPrice += 50;
    if (numAthletes === "51-100") totalPrice += 100;
    if (numAthletes === "101-200") totalPrice += 200;

    // Film retention prices
    if (filmRetention === "4 Months") totalPrice += 50;
    if (filmRetention === "6 Months") totalPrice += 80;
    if (filmRetention === "12 Months") totalPrice += 90;
    if (filmRetention === "Forever") totalPrice += 150;

    // Download permissions prices
    if (downloadPermissions === "5/mo") totalPrice += 23;
    if (downloadPermissions === "10/mo") totalPrice += 45;
    if (downloadPermissions === "20/mo") totalPrice += 85;
    if (downloadPermissions === "50/mo") totalPrice += 200;

    return totalPrice * quantity;
  };
     const totalPrice = calculateTotalPrice();
     const UpfrontPrice = calculateUpfront();

     const formattedTotalPrice = totalPrice > 0 ? `$${totalPrice.toLocaleString()}` : '';
     const formattedUpfrontPrice = UpfrontPrice > 0 ? `$${UpfrontPrice.toLocaleString()}` : '';

  return (
    <div className="purchase-page-template">
        <div className="product-img-box">
            <ImageCarousel
                images = {productimages}
            />
        </div>
        <div className="product-info">
            <div className="product-name">
                {productname}
            </div>
            <div className="product-description">
                {description}
            </div>
            <div className="product-description">
                {description2}
            </div>

            <div className="product-features">
                <div className="feature">
                Features
                </div>
                <ul>
                    {features.map((point, index) => (
                    <li key={index}>{point}</li>
                    ))}
                </ul>
            </div>
            <div className="subscription-box">
                <div className="Section-title">
                    Hardware
                </div>
                <div className="build-your-own">
                    Choose a Hardware Type:
                    <div className="subscription-row">
                        <div
                          className={`basic-sub ${hardware === "Basic" ? "selected" : ""}`}
                          onClick={() => setHardware("Basic")}
                        >
                          Basic
                          <div className="grey-price">
                            {leaseOrPurchase === "Lease" && hardware === "Premium" && "-$200"}
                            {leaseOrPurchase === "Lease" && hardware !== "Premium" && hardware !== "Basic" && "+$400"}
                            {leaseOrPurchase === "Purchase" && hardware === "Premium" && "-$2000"}
                            {leaseOrPurchase === "Purchase" && hardware !== "Premium" && hardware !== "Basic" && "+$10000"}


                          </div>
                        </div>
                        <div
                            className={`premium-sub ${hardware === "Premium" ? "selected" : ""}`}
                            onClick={() => setHardware("Premium")}
                        >
                            Premium
                            <div className="grey-price">
                                {leaseOrPurchase === "Lease" && hardware === "Basic" && "+$200"}
                                {leaseOrPurchase === "Lease" && hardware !== "Premium" && hardware !== "Basic" && "+$600"}
                                {leaseOrPurchase === "Purchase" && hardware === "Basic" && "+$2000"}
                                {leaseOrPurchase === "Purchase" && hardware !== "Premium" && hardware !== "Basic" && "+$12000"}

                          </div>
                        </div>
                    </div>
                </div>
                <div className="build-your-own">
                    Lease or Purchase?
                    <div className="subscription-row">
                       <div
                          className={`basic-sub ${leaseOrPurchase === "Lease" ? "selected" : ""}`}
                          onClick={() => setLeaseOrPurchase("Lease")}
                        >
                          Lease
                        </div>
                        <div
                            className={`premium-sub ${leaseOrPurchase === "Purchase" ? "selected" : ""}`}
                            onClick={() => setLeaseOrPurchase("Purchase")}
                        >
                            Purchase
                        </div>
                    </div>
                </div>
                <div className="Section-title">
                    Software
                </div>
                <div className="build-your-own">
                    Software Subscription
                    <div className="subscription-row">
                        <div
                            className={`premium-sub ${softwareSubscription === "Trial" ? "selected" : ""}`}
                            onClick={() => setSoftwareSubscription("Trial")}
                        >
                            Trial
                        <div className="grey-price">
                            {softwareSubscription === "Basic" &&  "-$10"}
                            {softwareSubscription === "Standard" &&  "-$75"}
                            {softwareSubscription === "Professional" &&  "-$275"}
                            {softwareSubscription === "Olympic" &&  "-$350"}
                            {softwareSubscription === "" && "$0"}
                        </div>


                        </div>
                        <div
                            className={`premium-sub ${softwareSubscription === "Basic" ? "selected" : ""}`}
                            onClick={() => setSoftwareSubscription("Basic")}
                        >
                            Basic
                            <div className="grey-price">
                                {softwareSubscription === "Trial" &&  "+$10"}
                                {softwareSubscription === "Standard" &&  "-$65"}
                                {softwareSubscription === "Professional" &&  "-$265"}
                                {softwareSubscription === "Olympic" &&  "-$340"}
                                {softwareSubscription === "" && "+$10"}
                            </div>
                        </div>
                        <div
                            className={`premium-sub ${softwareSubscription === "Standard" ? "selected" : ""}`}
                            onClick={() => setSoftwareSubscription("Standard")}
                        >
                            Standard
                            <div className="grey-price">
                                {softwareSubscription === "Trial" &&  "+$75"}
                                {softwareSubscription === "Basic" &&  "+$65"}
                                {softwareSubscription === "Professional" &&  "-$200"}
                                {softwareSubscription === "Olympic" &&  "-$275"}
                                {softwareSubscription === "" && "+75"}
                            </div>
                        </div>
                        <div
                            className={`premium-sub ${softwareSubscription === "Professional" ? "selected" : ""}`}
                            onClick={() => setSoftwareSubscription("Professional")}
                        >
                            Professional
                            <div className="grey-price">
                                {softwareSubscription === "Trial" &&  "+$275"}
                                {softwareSubscription === "Basic" &&  "+$265"}
                                {softwareSubscription === "Standard" &&  "+$200"}
                                {softwareSubscription === "Olympic" &&  "-$75"}
                                {softwareSubscription === "" && "+275"}
                            </div>
                        </div>
                        <div
                            className={`premium-sub ${softwareSubscription === "Olympic" ? "selected" : ""}`}
                            onClick={() => setSoftwareSubscription("Olympic")}
                        >
                            Olympic
                            <div className="grey-price">
                                {softwareSubscription === "Trial" &&  "+$350"}
                                {softwareSubscription === "Basic" &&  "+$340"}
                                {softwareSubscription === "Standard" &&  "+$200"}
                                {softwareSubscription === "Professional" &&  "+$75"}
                                {softwareSubscription === "" && "+350"}
                            </div>
                        </div>
                    </div>
                </div>

                {isSoftwareSelected && isTrial &&(
                    <div className="build-your-own">
                        Number of Athletes

                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${numAthletes === "1-25" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("1-25")}
                            >
                                1-10
                                <div className="grey-price">
                                    {numAthletes === "" && "$0"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isNumAthletesSelected && isTrial && (
                    <div className="build-your-own">
                        Film Retention
                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${filmRetention === "2 Months" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("2 Months")}
                            >
                                4 Weeks
                                <div className="grey-price">
                                    {filmRetention === "" &&  "$0"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isFilmRetentionSelected && isTrial && (
                    <div className="build-your-own">
                        Download Permissions
                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${downloadPermissions === "Pay" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("Pay")}
                            >
                                None
                                <div className="grey-price">
                                    {downloadPermissions === "" &&  "$0"}
                                </div>
                            </div>
                        </div>
                    </div>
                    )}





                {isSoftwareSelected && !isTrial &&(
                    <div className="build-your-own">
                        Number of Athletes

                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${numAthletes === "1-25" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("1-25")}
                            >
                                1-25
                                <div className="grey-price">
                                    {numAthletes === "26-50" &&  "-$50"}
                                    {numAthletes === "51-100" &&  "-$100"}
                                    {numAthletes === "101-200" &&  "-$200"}
                                    {numAthletes === "" && "$0"}

                                </div>
                            </div>



                            <div
                                className={`premium-sub ${numAthletes === "26-50" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("26-50")}
                            >
                                26-50
                                <div className="grey-price">
                                    {numAthletes === "1-25" &&  "+$50"}
                                    {numAthletes === "51-100" &&  "-$50"}
                                    {numAthletes === "101-200" &&  "-$150"}
                                    {numAthletes === "" && "$50"}

                                </div>

                            </div>
                            <div
                                className={`premium-sub ${numAthletes === "51-100" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("51-100")}
                            >
                                51-100
                                <div className="grey-price">
                                    {numAthletes === "1-25" &&  "+$100"}
                                    {numAthletes === "26-50" &&  "+$50"}
                                    {numAthletes === "101-200" &&  "-$100"}
                                    {numAthletes === "" && "$100"}

                                </div>
                            </div>
                            <div
                                className={`premium-sub ${numAthletes === "101-200" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("101-200")}
                            >
                                101-200
                                <div className="grey-price">
                                    {numAthletes === "1-25" &&  "+$200"}
                                    {numAthletes === "26-50" &&  "+$150"}
                                    {numAthletes === "51-100" &&  "+$100"}
                                    {numAthletes === "" && "$200"}

                                </div>

                            </div>

                            <div
                                className={`premium-sub ${numAthletes === "200+" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("200+")}
                            >
                                201+
                                <div className="grey-price">
                                    Custom

                                </div>

                            </div>

                        </div>
                    </div>
                )}
                {isNumAthletesSelected && !isTrial && (
                    <div className="build-your-own">
                        Film Retention
                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${filmRetention === "2 Months" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("2 Months")}
                            >
                                2 Months
                                <div className="grey-price">
                                    {filmRetention === "" &&  "$0"}
                                    {filmRetention === "4 Months" &&  "-$50"}
                                    {filmRetention === "6 Months" &&  "-$80"}
                                    {filmRetention === "12 Months" &&  "-$90"}
                                    {filmRetention === "Forever" && "-$150"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${filmRetention === "4 Months" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("4 Months")}
                            >
                                4 Months
                                <div className="grey-price">
                                    {filmRetention === "" &&  "$50"}
                                    {filmRetention === "2 Months" &&  "+$50"}
                                    {filmRetention === "6 Months" &&  "-$30"}
                                    {filmRetention === "12 Months" &&  "-$40"}
                                    {filmRetention === "Forever" && "-$100"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${filmRetention === "6 Months" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("6 Months")}
                            >
                                6 Months
                                <div className="grey-price">
                                    {filmRetention === "" &&  "$80"}
                                    {filmRetention === "2 Months" &&  "+$80"}
                                    {filmRetention === "4 Months" &&  "+$30"}
                                    {filmRetention === "12 Months" &&  "-$10"}
                                    {filmRetention === "Forever" && "-$70"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${filmRetention === "12 Months" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("12 Months")}
                            >
                                12 Months
                                <div className="grey-price">
                                    {filmRetention === "" &&  "$90"}
                                    {filmRetention === "2 Months" &&  "+$90"}
                                    {filmRetention === "4 Months" &&  "+$40"}
                                    {filmRetention === "6 Months" &&  "+$10"}
                                    {filmRetention === "Forever" && "-$60"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${filmRetention === "Forever" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("Forever")}
                            >
                                Forever
                                <div className="grey-price">
                                    {filmRetention === "" &&  "$150"}
                                    {filmRetention === "2 Months" &&  "-$150"}
                                    {filmRetention === "4 Months" &&  "-$100"}
                                    {filmRetention === "6 Months" &&  "-$70"}
                                    {filmRetention === "12 Months" && "+$60"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isFilmRetentionSelected && !isTrial && (
                    <div className="build-your-own">
                        Download Permissions
                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${downloadPermissions === "Pay" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("Pay")}
                            >
                                None
                                <div className="grey-price">
                                    {downloadPermissions === "" &&  "$0"}
                                    {downloadPermissions === "5/mo" &&  "-$23"}
                                    {downloadPermissions === "10/mo" &&  "-$45"}
                                    {downloadPermissions === "20/mo" &&  "-$85"}
                                    {downloadPermissions === "50/mo" && "-$200"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${downloadPermissions === "5/mo" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("5/mo")}
                            >
                                5/month
                                <div className="grey-price">
                                    {downloadPermissions === "" &&  "$23"}
                                    {downloadPermissions === "Pay" &&  "-$23"}
                                    {downloadPermissions === "10/mo" &&  "-$22"}
                                    {downloadPermissions === "20/mo" &&  "-$62"}
                                    {downloadPermissions === "50/mo" && "-$177"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${downloadPermissions === "10/mo" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("10/mo")}
                            >
                                10/month
                                <div className="grey-price">
                                    {downloadPermissions === "" &&  "$45"}
                                    {downloadPermissions === "Pay" &&  "+$45"}
                                    {downloadPermissions === "5/mo" &&  "+$22"}
                                    {downloadPermissions === "20/mo" &&  "-$40"}
                                    {downloadPermissions === "50/mo" && "+$155"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${downloadPermissions === "20/mo" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("20/mo")}
                            >
                                20/month
                                <div className="grey-price">
                                    {downloadPermissions === "" &&  "$85"}
                                    {downloadPermissions === "5/mo" &&  "+$62"}
                                    {downloadPermissions === "10/mo" &&  "+$40"}
                                    {downloadPermissions === "50/mo" &&  "-$115"}
                                    {downloadPermissions === "Pay" && "+$85"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${downloadPermissions === "50/mo" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("50/mo")}
                            >
                                50/month
                                <div className="grey-price">
                                    {downloadPermissions === "" &&  "$200"}
                                    {downloadPermissions === "5/mo" &&  "+$177"}
                                    {downloadPermissions === "10/mo" &&  "+$145"}
                                    {downloadPermissions === "20/mo" &&  "+$115"}
                                    {downloadPermissions === "Pay" && "+$200"}
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
            </div>
            <div className="product-price">
              {UpfrontPrice !== 0 && `${formattedUpfrontPrice}`}
              {UpfrontPrice !== 0 && totalPrice !== 0 && ' + '}
              {totalPrice !== 0 && `${formattedTotalPrice} /month`}
            </div>
            <div className="purchase-box">
                <div className="quantity-selector">
                    <button className="decrease-qnt" onClick={handleDecrease}>
                        <img className="selector-img"  src={require("../../static/img/---icon--minus-cirlce-@2x.png")} alt="icon &quot;minus cirlce&quot;"/>
                    </button>
                    <div className="qnt-count">
                        {quantity}
                    </div>
                    <button className="increase-qnt" onClick={handleIncrease}>
                        <img className="selector-img" src={require("../../static/img/---icon--add-circle-@2x.png")} alt="icon &quot;add circle&quot;"/>
                    </button>
                </div>
                    <button className="purchase-button" disabled={!isPurchaseEnabled()} onClick={handlePurchaseClick}>
                        Purchase
                    </button>
            </div>
            {purchaseCompleted && <div className="purchase-complete">Enjoy the Swimbot!</div>}

        </div>
    </div>
  );
}

export default PurchaseProductTemplate;
