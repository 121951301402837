import React from 'react';
import './ContactForm.css';

function ContactForm() {
  return (
    <div className="contact-form">
        I'm interested in...
        <div className="interests-box">
          <button className="product-interests">
              Swimbot
          </button>
          <button className="product-interests">
              Other
          </button>
        </div>
        I am a...
        <div className="roles-box">
          <button className="role-button">
              Coach
          </button>
          <button className="role-button">
              Athlete
          </button>
          <button className="role-button">
              Other
          </button>
        </div>
          <input
            type="name"
            className="form-control mt-1 contact-form-input"
            placeholder="Your name"
          />
          <input
            type="email"
            className="form-control mt-1 contact-form-input"
            placeholder="Your email"
          />
          <textarea
            type="message"
            className="message-area contact-form-input"
            placeholder="Your message"
          />
          <button className="send-message-button">
              Send
          </button>
    </div>
  );
}

export default ContactForm;
