import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import Select from 'react-select';
import "./GetStartedCoach.css";
import HomeLogo from "../../components/HomeLogo";
import GetStartedTemplate from "../../components/GetStartedTemplate";
import { BASE_URL } from "../../constants/company-info";

function GetStartedCoach() {
  const [teams, setTeams] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [newTeam, setNewTeam] = useState('');
  const history = useHistory();

  const location = useLocation();
  const userId = location.state?.userId;
  const sport = location.state?.sport;
  const role = location.state?.role;
  const name = location.state?.name;

  const isCoach_str = localStorage.getItem('is_coach');

  if (!userId || isCoach_str !== 'TBD') {
    if (isCoach_str === 'false') {
      return <Redirect to='/my-film' />
    }
    else {
      return <Redirect to='/manage-teams' />
    }
  };

  const allFieldsFilled = newTeam || teams;

  useEffect(() => {
    loadAllTeams();
  }, []);

  const loadAllTeams = async () => {
    try {
      const response = await fetch(BASE_URL + '/api/get_all_teams/', {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const teamsWithIdAndName = data.map(team => ({
        value: team.id,
        label: team.name,
        id: team.id,
      }));
      setAllTeams(teamsWithIdAndName);
    } catch (error) {
      console.error('Error fetching all teams: ', error);
    }
  };

  const handleNextPage = async () => {
    let teamId = "";

    if (newTeam) { // create a new team
      teamId = await createTeam(newTeam);
    }
    else if (teams) { // retrieve team ID of existing team
      teamId = await getTeamId(teams);
    }

    await registerCoach(teamId);

    history.push({
      pathname: '/manage-teams',
      state: { userId: userId, name: name }
    });
  };

  const handleChange = (selectedOptions) => {
    setTeams(selectedOptions || []);
  };

  // Helper function to create a new team
  const createTeam = async (teamName) => {
    const data = {
      "name": teamName,
      "description": "filler team description",
      "sport": 1
    };

    try {
      const response = await fetch(BASE_URL + '/api/teams/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      const jsonData = await response.json();
      return jsonData["id"];
    } catch (error) {
      alert(error);
      console.error('Error retrieving team ID: ', error);
      return null;
    }
  }

  // Helper function to get the ID of an existing team
  const getTeamId = async (teamName) => {
    const data = {
      "name": teamName
    };

    try {
      const response = await fetch(BASE_URL + '/api/search_team/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      const jsonData = await response.json();
      return jsonData["id"];
    } catch (error) {
      alert(error);
      console.error('Error retrieving team ID: ', error);
      return null;
    }
  };

  // Register coach
  const registerCoach = async (teamId) => {
    const data = {
      "user": userId,
      "team": teamId,
      "history": "history here",
      "description": "desc here"
    };

    try {
      const response = await fetch(BASE_URL + '/api/coaches/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });

      const jsonData = await response.json();

      localStorage.setItem('is_coach', 'true');
      
      // Associate coach with team
      await addTeamToCoach(jsonData["id"], teamId);

    } catch (error) {
      localStorage.removeItem('is_coach');
      console.error('Error creating Coach: ', error);
      return null;
    }
  };

  // Associate coach with team
  const addTeamToCoach = async (coachId, teamId) => {
    const data = {
      "coach_id": coachId,
      "team_id": teamId
    };

    try {
      const response = await fetch(BASE_URL + '/api/add_team_to_coach/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error('Error associating team and coach: ', error);
      return null;
    }
  };

  const formContent = (
    <div className="form">
      <div className="team-option" style={{"marginTop": "-15px"}}>Create a Team</div>
      <label style={{"marginTop": "15px", "marginBottom": "5px"}}>Create a team name</label>
      <input
          type="text"
          className="form-control mb-1"
          placeholder="New Team"
          value={newTeam}
          onChange={(e) => {setNewTeam(e.target.value);}}
      />
      <div className="team-option" style={{"marginTop": "15px"}}>Join a Team</div>
      <label style={{"marginTop": "15px"}}>Select a team or join as an individual</label>
      <Select
        className="select-team"
        placeholder="Select or type a team name"
        isClearable
        isSearchable
        name="team"
        options={allTeams}
        onChange={handleChange}
        value={teams}
      />

      <button
          className="submit-log-in"
          disabled={!allFieldsFilled}
          onClick={handleNextPage}
          style={{"marginTop": "30px"}}
        >
          Next
        </button>
    </div>
  );

  return (
    <div className="login-page">
      <div className='background'></div>
      <HomeLogo />
      <GetStartedTemplate
        title={"Get Started!"}
        form={formContent}
      />
    </div>
  );
}

export default GetStartedCoach;
