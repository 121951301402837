import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Markup.css";
import HomeLogo from "../../components/HomeLogo";
import LogInTemplate from "../../components/LogInTemplate";



function Markup() {
  const formContent = (
    <div className="form">
      <label>Enter your username or email address</label>
      <input
        type="email"
        className="form-control mt-1"
        placeholder="Username or email address"
      />
      <label>Enter your password</label>
      <input
        type="password"
        className="form-control mt-1"
        placeholder="Password"
      />
      <Link className='forgot-password' to="/desktop-default">forgot password</Link>
      <div className="submit-log-in">
        Log in
      </div>
    </div>
  );

  return (
    <div className="login-page">
      <div className='background'></div>
      <HomeLogo />
      <LogInTemplate
        needOrHaveAnAccount={"Don't have an account?"}
        signUpOrLogIn={<Link to="/sign-up">Sign up</Link>}
        title={"Log in"}
        form={formContent}
      />
    </div>
  );
}

export default Markup;
