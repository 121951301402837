function table1() {
    return (
        <div>
            <table border="1">
            <thead>
                <tr>
                <th>Metric</th>
                <th>Video 1</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Stroke Count</td>
                <td>45</td>
                </tr>
                <tr>
                <td>Stroke Rate (SPM)</td>
                <td>36</td>
                </tr>
                <tr>
                <td>Distance per Stroke</td>
                <td>2</td>
                </tr>
                <tr>
                <td>Average Lap Time (s)</td>
                <td>19</td>
                </tr>
            </tbody>
            </table>
        </div>
    );
}

export default table1;