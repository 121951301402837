// RoundedButton.js
import React from "react";
import "./RoundedButton.css";

function RoundedButton({ label, onClick }) {
  return (
    <button className="rounded-button" onClick={onClick}>
      {label}
    </button>
  );
}

export default RoundedButton;
