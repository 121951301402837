import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./ManageTeams.css";
import HomeLogo from "../../components/HomeLogo";
import ProfileIcon from "../../components/ProfileIcon";
import TeamBox from "../../components/TeamBox";
import AthleteDisplay from "../../components/AthleteDisplay";
import { useLocation } from 'react-router-dom';
import HeaderMotto from "../../components/HeaderMotto";
import { BASE_URL } from "../../constants/company-info";

function ManageTeams() {

  const location = useLocation();
  const userId = location.state?.userId || localStorage.getItem('userId');
  const name = location.state?.name || localStorage.getItem('name');
  const history = useHistory();

  const [teams,setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [coachId, setCoachId] = useState(-1)

  useEffect(() => {
    if (userId) {
        fetch(BASE_URL + '/api/get_coachid?user_id='+userId, {
            method: "GET",
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
          localStorage.setItem('coachId', data[0]);
          setCoachId(data[0]);
        })
        .catch(error => {
            console.error('Not a coach: ', error);
        });
    }
}, [coachId]);

  useEffect (() => {
    // get teams
    if (userId) {
      fetch(BASE_URL + '/api/get_teams?user_id='+userId, {
          method: "GET",
          credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            setTeams(data)
            if (data.length > 0) {
              setSelectedTeam(data[0].id);
            }
        })
        .catch(error => {
            console.error('Error getting teams: ', error);
        });
      }
  }, []);

  const handleTeamSelect = (teamId) => {
    setSelectedTeam(teamId);
  };

  const handleAthleteSelection = (athleteName, athleteId) => {

    history.push({
      pathname: '/analyze', // The target route
      state: {
        athleteName: athleteName,
        athleteId: athleteId,
        coachName: name,
        userId: userId,
        isCoach: true,
      }
    });
  };

  const handleAllFilmButton = () => {


    history.push({
        pathname: '/all-films', // The target route
        state: {
            userId: userId,
            coachId: coachId,
            coachName: name,
        }
      });
  };

  return (
    <div className="manage-teams-page">
        <HomeLogo/>
        <HeaderMotto/>
        <ProfileIcon
          userId={userId}
        />
        <div className="welcome-user">
            Welcome, {name}
        </div>
        <div className="my-teams-text">
                My Teams
        </div>
        <div className="select-team-text">
            Select an existing team to view your athletes.
        </div>

        <div className="team-athlete-selection">
            <div className="team-selection">
                {teams.map((team) => (
                    <TeamBox
                        key={team.id}
                        name={team.name}
                        onClick={() => handleTeamSelect(team.id)}
                        selected={team.id === selectedTeam}
                    />
                ))}
            </div>

            {selectedTeam && (
                <div className="athlete-selection">
                    <div className="your-athletes-text">
                        Athletes
                    </div>
                    <AthleteDisplay
                        key={selectedTeam}
                        teamId={selectedTeam}
                        coachUserId={userId}
                        onSelectAthlete={handleAthleteSelection}
                    />
                </div>
            )}

          <div className="all-films-button" onClick={handleAllFilmButton}>
              View All Films
          </div>
        </div>
        

    </div>
  );
}

export default ManageTeams;
