import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { BASE_URL } from '../../constants/company-info';
import './ProfileIcon.css';
import AuthContext from '../../context/AuthContext';

function ProfileIcon({userId, flip_on_change=false, onLogout=null}) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
    const [notifications, setNotifications] = useState([{ id: -1, message: 'No notifications', onClick: () => {} }])
    const [coachId, setCoachId] = useState(-1);
    const [coachName, setCoachName] = useState("");
    const history = useHistory();
    const profileDropdownRef = useRef(null);
    const notificationDropdownRef = useRef(null);
    const location = useLocation();
    const [isCoach, setIsCoach] = useState(false);
    const name = location.state?.name;
    const address = location.state?.address;
    const { logout } = useContext(AuthContext);

    const handleLogout = () => {
        logout();
        history.push('/'); // Redirect to login page after logout
    };
    
    const setCoachDetails = (id, name) => {
        setCoachName(name);
        setCoachId(id);
        setIsCoach(true);
    };
    
    useEffect(() => {
        if (userId) {
            fetch(BASE_URL + '/api/get_coachid?user_id='+userId, {
                method: "GET",
                credentials: 'include'
            })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    return;
                }
                setCoachDetails(data[0], data[1]);
            })
            .catch(error => {
                setIsCoach(false);
                console.error('Not a coach: ', error);
            });
        } else {
            setIsCoach(false);
        }
    }, [userId, coachId, coachName]);

      const handleAssignFilm = (videoId, videoIndex, athleteName, athleteId) => {


        history.push({
            pathname: '/all-films', // The target route
            state: {
                userId: userId,
                coachId: coachId,
                coachName: coachName,
            }
          });
      };

useEffect(() => {
    if (userId) {
        fetch(BASE_URL + '/api/get_notifications/?user_id=' + userId, {
            method: "GET",
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            const notificationsArray = data.notifications;
            console.log(notificationsArray);

            if (notificationsArray && notificationsArray.length > 0) {
                setNotifications(notificationsArray.map(notification => ({
                    id: notification.id,
                    message: notification.message,
                    onClick: () => {
                        // Handle the notification click event
                        console.log(`Notification clicked: ${notification.message}`);
                    }
                })));
            } else {
                setNotifications([{ id: -1, message: 'No notifications', onClick: () => {} }]);
            }
        })
        .catch(error => {
            console.error('Error fetching notifications:', error);
            setNotifications([{ id: -1, message: 'No notifications', onClick: () => {} }]);
        });
    }
}, [userId]);

        // useEffect(() => {
        //     if (coachId > -1) {
        //         fetch(BASE_URL + '/api/get_unassigned_film_count?coachUserId=' + userId, {
        //             method: "GET",
        //             credentials: 'include'
        //         })
        //         .then(response => response.json())
        //         .then(data => {
        //             if (data > 0) {
        //                 msg = `You have ${data} unassigned film`;
        //                 if (data === 1) {
        //                     msg += '!';
        //                 } else {
        //                     msg += 's!';
        //                 }
        //                 const notificationList = [{ id: 1, message: msg, onClick: handleAssignFilm }];
        //                 setNotifications(notificationList);
        //             } else {
        //                 const notificationList = [{ id: -1, message: 'No notifications', onClick: () => {} }];
        //                 setNotifications(notificationList);
        //             }
        //         })
        //         .catch(error => {
        //             console.log('No unassigned videos: ', error);
        //         });
        //     }
        // }, [userId, coachId, flip_on_change]);

    const toggleDropdown = () => {
        if (!isDropdownOpen) {
            notificationCloseDropdown()
        }
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    const handleProfileClick = () => {
    closeDropdown(); // Close the dropdown before navigating
        history.push({
          pathname: isCoach ? `/coach-profile/` : `/profile/`,
          state: {
            userId: userId,
            name: name,
            address: address,
          }
        });
    };

    const notificationToggleDropdown = () => {
        if (!isNotificationDropdownOpen) {
            closeDropdown();
        }
        setIsNotificationDropdownOpen(!isNotificationDropdownOpen);
    };

    const notificationCloseDropdown = () => {
        setIsNotificationDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
            if (notificationDropdownRef.current && !notificationDropdownRef.current.contains(event.target)) {
                notificationCloseDropdown();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="profile-container" ref={profileDropdownRef}>
                <img
                    className="profile-icon"
                    src={require("../../static/img/---icon--profile-circle-@2x.png")}
                    alt="icon profile circle"
                    onClick={toggleDropdown}
                />
                {isDropdownOpen && (
                    <div className="dropdown-menu" onClick={closeDropdown}>
                        <ul>
                            <li key='profile-info' onClick={handleProfileClick}>Profile</li>
                            <li key='logout' onClick={onLogout || handleLogout}>Log out</li>
                        </ul>
                    </div>
                )}
            </div>

            <div className="notification-container" ref={notificationDropdownRef}>
                <img
                    className="notification-icon"
                    src={require("../../static/img/notifications-circle-outline-svgrepo-com.png")}
                    alt="notification icon"
                    onClick={notificationToggleDropdown}
                />
                
                {notifications[0]['id'] !== -1 && (
                    <div className="notification-badge">{notifications.length}</div>
                )}

                {isNotificationDropdownOpen && (
                    <div className="notification-dropdown-menu" onClick={notificationCloseDropdown}>
                        <ul>
                        {notifications.map(notification => (
                            <li key={notification.id} onClick={notification.onClick}>
                                {notification.message}
                            </li>
                        ))}
                        </ul>
                    </div>
                )}
            </div>

        </>
    );
}

export default ProfileIcon;
