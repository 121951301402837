import React, { useEffect, useState } from "react";
import "./AssignFilm.css";
import HomeLogo from "../../components/HomeLogo";
import ProfileIcon from "../../components/ProfileIcon";
import TeamBox from "../../components/TeamBox";
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import FilmBox from "../../components/FilmBox";
import { BASE_URL } from "../../constants/company-info";
import RoundedButton from "../../components/RoundedButton";

function AssignFilm() {
  const location = useLocation();
  const history = useHistory();
  const userId = location.state?.userId || localStorage.getItem('userId');
  const coach_id = location.state?.coachId || localStorage.getItem('coachId');
  
  if (!coach_id) {
    return <Redirect to='/my-film' />
  };

  const coach_name = location.state?.coachName || localStorage.getItem('name');
  const [flipOnChange, setFlipOnChange] = useState(false);

  const [films, setFilms] = useState([]);
  const [athletes, setAthletes] = useState({});

  const [unassignedVidURL, setUnassignedVidURL] = useState(null)

  const handleUnassignedVideoClick = (url) => {
    setUnassignedVidURL(url)
  }

  const handleCloseUnassignedVideoClick = (event) => {
    setUnassignedVidURL(null)
  }

  // Route to a video on the analyze page
  const handleSelectFilm = (videoId, videoIndex, athleteId, athleteName) => {

    history.push({
        pathname: '/analyze', // The target route
        state: {
          athleteName: athleteName,
          athleteId: athletes[athleteId][1],
          coachName: coach_name,
          name: coach_name,
          userId: userId,
          videoId: videoId,
          videoIndex: videoIndex,
          isCoach: true,
        }
      });
  };

  const processFilms = (data) => {
    const filmsByAthlete = data.reduce((acc, film) => {
      const athleteId = film[4] || 'Unassigned Videos';
      if (!acc[athleteId]) {
        acc[athleteId] = {'films': [], 'name': film[5] || 'Unassigned Videos'};
      }
      acc[athleteId]['films'].push(film);
      return acc;
    }, {});
    return filmsByAthlete;
  };

    useEffect(() => {
        fetch(`${BASE_URL}/api/get_all_athletes?coachId=${coach_id}`, {
        method: "GET",
        credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            // Assuming data format is suitable for setting athletes
            const athletesData = data.reduce((acc, athlete) => {
            acc[athlete.id] = [athlete.name, athlete.athlete_user_id];
            return acc;
            }, {});
            setAthletes(athletesData);
        })
        .catch(error => {
            console.error('Error getting athletes: ', error);
        });
    }, [coach_id]);

  const handleAssignFilm = (videoId, newAthleteId, currentAthleteId) => {
    fetch(BASE_URL + '/api/assign_video/', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: 'include',
      body: JSON.stringify({ videoId, newAthleteId })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        const updatedFilms = { ...films };

        // Add to the new athlete or 'Unassigned Videos'
        if (newAthleteId === 'Unassigned Videos') {
            if (!updatedFilms['Unassigned Videos']) {
                updatedFilms['Unassigned Videos'] = { films: [], name: 'Unassigned Videos' };
            }
            updatedFilms['Unassigned Videos']['films'].push(films[currentAthleteId]['films'].find(film => film[2] === videoId));
        } else {
            if (!updatedFilms[newAthleteId]) {
                updatedFilms[newAthleteId] = { films: [], name: athletes[newAthleteId][0] };
            }
            updatedFilms[newAthleteId]['films'].push(films[currentAthleteId]['films'].find(film => film[2] === videoId));
        }
  
          // Remove the video from the current athlete or 'Unassigned Videos'
        if (currentAthleteId) {
          if (currentAthleteId !== 'Unassigned Videos') {
            updatedFilms[currentAthleteId]['films'] = updatedFilms[currentAthleteId]['films'].filter(film => film[2] !== videoId);
            if (updatedFilms[currentAthleteId]['films'].length === 0) {
              delete updatedFilms[currentAthleteId];
            }
          } else {
            updatedFilms['Unassigned Videos']['films'] = updatedFilms['Unassigned Videos']['films'].filter(film => film[2] !== videoId);
          }
        }
        
        setFilms(updatedFilms);
        setFlipOnChange(!flipOnChange);
      } else {
        console.error('Failed to assign video');
      }
    })
    .catch(error => {
      console.error('Error assigning video: ', error);
    });
  };

    
  useEffect(() => {
    // Fetch films for coach
    fetch(BASE_URL + '/api/get_film?coachUserId=' + userId, {
      method: "GET",
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        const processedFilms = processFilms(data);
        setFilms(processedFilms);
      })
      .catch(error => {
        console.error('Error getting videos for this coach: ', error);
      });
  }, [userId]);

      
  return (
    <div className="assign-film-page">
      <div className="header">
        <HomeLogo/>
        <ProfileIcon userId={userId} flip_on_change={flipOnChange}/>
      </div>
      <div className="welcome-user">
          Welcome, {coach_name}
      </div>

      <div className="assign-film-header">
        <div className="assign-film-title" >My Films</div>
      </div>
        
        <div className="athlete-videos">
            {/* Render Unassigned Videos only if available */}
            {films['Unassigned Videos'] && films['Unassigned Videos']['films'].length > 0 && (
                <div className="athlete-section">
                    <div className="assign-film-desc" style={{ marginBottom: '15px' }}>Unassigned Videos</div>
                    <div className="assign-films-grid">
                        {films['Unassigned Videos']['films'].map((film, index) => (
                        <FilmBox 
                            key={film[2]}
                            url={film[0]}
                            thumbnail={film[1]}
                            favorited={false}
                            assignPage={true}
                            onClick={() => handleUnassignedVideoClick(film[0])}
                            onAssign={(newAthleteId) => handleAssignFilm(film[2], newAthleteId, 'Unassigned Videos')}
                            athletes={athletes}
                            currentAthleteId='Unassigned Videos'
                        />
                        ))}
                    </div>
                </div>
            )}

            {/* Render Athletes header and their videos */}
            {Object.keys(films).length === 0 ? (
                <p className="no-films-msg">No films found.</p>
            ) : (
                Object.entries(films).map(([athleteId, athleteFilms]) => {
                    if (athleteId === 'Unassigned Videos' || films[athleteId]['films'].length === 0) {
                        return null; // Skip Unassigned Videos since it is handled separately, and those athletes which don't have videos
                    }
                    return (
                        <div key={athleteId} className="athlete-section">
                            <div className="assign-film-desc" style={{ marginBottom: '15px' }}>{athleteFilms['name']}</div>
                            <div className="assign-films-grid">
                                {athleteFilms['films'].map((film, index) => (
                                <FilmBox 
                                    key={film[2]}
                                    url={film[0]}
                                    thumbnail={film[1]}
                                    favorited={false}
                                    assignPage={true}
                                    onClick={() => handleSelectFilm(film[2], index, athleteId, athleteFilms['name'])}
                                    onAssign={(newAthleteId) => handleAssignFilm(film[2], newAthleteId, athleteId)}
                                    athletes={athletes}
                                    currentAthleteId={athleteId}
                                />
                                ))}
                            </div>
                        </div>
                    );
                })
            )}
        </div>

        {unassignedVidURL && (
          <div className="video-overlay">
              <iframe
                  title="Unassigned Swimmer Video"
                  src={unassignedVidURL}
                  allowFullScreen
              />
              <RoundedButton label='❌' onClick={handleCloseUnassignedVideoClick} />
          </div>
      )}
    </div>
  );
}

export default AssignFilm;
