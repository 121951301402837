import React, { useState, useRef, useEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import "./PurchaseProductTemplate.css";

function PurchaseProductTemplate(props) {
    const { productname, productimages, description, description2, features} = props;
    const [quantity, setQuantity] = useState(1);
    const [hardware, setHardware] = useState("");
    const [leaseOrPurchase, setLeaseOrPurchase] = useState("");
    const [softwareSubscription, setSoftwareSubscription] = useState("");
    const [numAthletes, setNumAthletes] = useState("");
    const [filmRetention, setFilmRetention] = useState("");
    const [downloadPermissions, setDownloadPermissions] = useState("");
    const [purchaseCompleted, setPurchaseCompleted] = useState(false);
    const isSoftwareSelected = softwareSubscription !== "";
    const isNumAthletesSelected = numAthletes !== "";
    const isFilmRetentionSelected = filmRetention !== "";
    const isTrial = softwareSubscription === "Trial";

    const isPurchaseEnabled = () => {
        return hardware && leaseOrPurchase && softwareSubscription && numAthletes && filmRetention && downloadPermissions;
    };
    const handlePurchaseClick = () => {
        if (isPurchaseEnabled()) {
            setPurchaseCompleted(true);
        }
    };

    const handleIncrease = () => {setQuantity(prevQuantity => prevQuantity + 1);};
    const handleDecrease = () => {setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 1));};

        const calculateUpfront = () => {
    let upfront = 0;

    // Lease/Purchase prices
    if (hardware === "Premium"  && leaseOrPurchase === "Purchase") upfront = 12000;
    if (hardware === "Basic"  && leaseOrPurchase === "Purchase") upfront = 10000;


    return upfront * quantity;
  };

     const calculateTotalPrice = () => {
    let totalPrice = 0;

    if (hardware === "Premium"  && leaseOrPurchase === "Lease") totalPrice +=600;
    if (hardware === "Basic"  && leaseOrPurchase === "Lease") totalPrice += 400;

    // Software subscription prices
    if (softwareSubscription === "Basic") totalPrice += 10;
    if (softwareSubscription === "Standard") totalPrice += 75;
    if (softwareSubscription === "Professional") totalPrice += 275;
    if (softwareSubscription === "Olympic") totalPrice += 350;

    if (numAthletes === "26-50") totalPrice += 50;
    if (numAthletes === "51-100") totalPrice += 100;
    if (numAthletes === "101-200") totalPrice += 200;

    // Film retention prices
    if (filmRetention === "4 Months") totalPrice += 50;
    if (filmRetention === "6 Months") totalPrice += 80;
    if (filmRetention === "12 Months") totalPrice += 90;
    if (filmRetention === "Forever") totalPrice += 150;

    // Download permissions prices
    if (downloadPermissions === "5/mo") totalPrice += 23;
    if (downloadPermissions === "10/mo") totalPrice += 45;
    if (downloadPermissions === "20/mo") totalPrice += 85;
    if (downloadPermissions === "50/mo") totalPrice += 200;

    return totalPrice * quantity;
  };
     const totalPrice = calculateTotalPrice();
     const UpfrontPrice = calculateUpfront();

     const formattedTotalPrice = totalPrice > 0 ? `$${totalPrice.toLocaleString()}` : '';
     const formattedUpfrontPrice = UpfrontPrice > 0 ? `$${UpfrontPrice.toLocaleString()}` : '';

  const [showPopup, setShowPopup] = useState(false);
  const [activePopup, setActivePopup] = useState(null);
  const togglePopup = (event, subscription) => {
    event.stopPropagation(); // Prevents the event from bubbling to the parent
    setActivePopup(subscription); // Set the active popup based on the subscription
    setShowPopup(true); // Show popup when clicking the icon
  };

  const closePopup = (event) => {
    event.stopPropagation();
    setShowPopup(false);
    setActivePopup(null);
    setActiveItems([]);
  };

  const infoDictionary = {
    Trial1: "Film access available",
    BasicSoft1: "Film access available.",
    BasicSoft2: "How fast the swimmer moves forward",
    Basic1: "Standard SwimmerBot model with one camera underwater",
    Basic2: "Top speed of 4.5 mph",
    Premium1: "Upgraded SwimmerBot model with one underwater camera and one overwater camera",
    Premium2: "Top speed of 5.8 mph",
    Standard1: "How fast the swimmer moves forward",
    Standard2: "How many strokes the swimmer took in the distance filmed",
    Standard3: "How many strokes the swimmer would take per 50 meters",
    Standard4: "Number of seconds per stroke",
    Standard5: "How much above or below the average tempo the highest/lowest 10% of strokes are (Measured as a percentage. A tempo deviation of 0% indicates perfectly consistent timing between every stroke, while a higher value indicates less consistent timing between strokes)",
    Standard6: "Change in tempo during the final quarter of filmed distance (Measured as a percentage. 0% tempo falloff indicates perfectly average tempo for the last quarter of the filmed distance, a positive number indicates a faster tempo for the last quarter of filmed distance, and a negative number indicates a slower tempo for the last quarter of filmed distance)",
    Standard7: "How far the swimmer travels during one stroke",
    Standard8: "Measure of swimmer efficiency calculated by multiplying distance per stroke, speed, and a stroke-specific number (2 for freestyle and backstroke, 1 for breaststroke and butterfly)",
    Standard9: "Number of strokes per minute",
    Standard10: "Number of breaths taken over the filmed distance",
    Standard11: "Estimate of how many breaths the swimmer might take per 50 meters (likely not scalable to 25m or 100m – these would have to be separate metrics)",
    Standard12: "Distance traveled between breaths",
    Standard13: "Distance traveled while taking a breath",
    Standard14: "A set of two numbers, the first of which is the speed of the swimmer during their breath, the second of which is how much they slow down (negative number) or speed up (positive number) on average during their breaths",
    Pro1: "Stroke rate for the first six strokes filmed",
    Pro2: "Stroke rate for the final six strokes filmed",
    Pro3: "Stroke rate for all other strokes not included in SR - Cycle Start or SR - Cycle End",
    Pro4: "Measure of swimmer efficiency calculated by adding the number of strokes in one pool length and the time it took for the swimmer to swim that length",
    Pro5: "Overwater Speed: Speed of swimmer during overwater portion of their stroke (for butterfly and breast stroke)",
    Pro6: "Overwater Time: Number of seconds the swimmer is in the overwater portion of their stroke during the distance filmed (for butterfly and breast stroke)",
    Pro7: "Underwater Speed: Speed of swimmer during underwater portion of their stroke (for butterfly and breast stroke)",
    Pro8: "Underwater Time: Number of seconds the swimmer is in the underwater portion of their stroke during the distance filmed (for butterfly and breast stroke)",
    Pro9: "Percentage Underwater: Percentage of total distance filmed that the swimmer travels in the underwater portion of their stroke (for butterfly and breast stroke)",
    Pro10: "Max Depth Underwater: Estimate of maximum depth the swimmer reaches, including during dives and turns. Also includes location of maximum depth measured in meters from start",
    Pro11: "Average maximum angle that the swimmer's body rotates side to side during each stroke (for freestyle and backstroke)",
    Pro12: "Transition Time: Time for the swimmer to fully transition from the end of one stroke to the start of the next, including turn time (for individual medley only)",
    Pro13: "Total time from gun to breakout",
    Pro14: "Number of kicks swimmer takes underwater between dive and breakout",
    Pro15: "Number of kicks per minute between dive and breakout",
    Pro16: "Number of seconds per kick between dive and breakout",
    Olympic1: "Estimated depth of contact with the wall during a turn",
    Olympic2: "Total time from the start of the swimmer's turn to their first stroke off the wall",
    Olympic3: "Amount of time the swimmer is actively pushing off the wall during a turn",
    Olympic4: "Maximum acceleration produced by the swimmer's push off the wall during a turn",
    Olympic5: "Estimate of maximum force (in pounds or kilograms) produced by the swimmer's push off the wall during a turn",
    Olympic6: "Estimate of average force (in pounds or kilograms) produced by the swimmer's push off the wall during a turn",
    Olympic7: "Estimate of maximum speed reached by the swimmer as they push off the wall during a turn",
    Olympic8: "Measure of how consistent knee and hip movements are between strokes and how optimal these positions are for maximum efficiency. Greater numbers indicate more precise kicks. (Calculated by adding the standard deviations of maximum knee and hip angle measurements per stroke, and then subtracting this number from a stroke constant (5 for freestyle and backstroke, 10 for butterfly, and 20 for breaststroke))",
    Olympic9: "Amount of time between the gun and when the swimmer's hips enter the water",
    Olympic10: "Amount of time between the swimmer's feet leaving the block and first contact with the water",
    Olympic11: "Amount of time between the gun and the swimmer's feet leaving the block",
    Olympic12: "Average height of finger tips from surface of the water during forward motion of the release (freestyle only)",
    Olympic13: "Average excess drag caused by improper hand contact with the water. Should always be 0 for most experienced swimmers",
  };

  const highlightParentheses = (text) => {
  const regex = /\(([^)]+)\)/; // Regular expression to find text inside parentheses
  const parts = text.split(regex); // Split the text by the parentheses

  return (
    <>
      {parts[0]} {/* Text before parentheses */}
      {parts[1] && <span className="parentheses-text">({parts[1]})</span>} {/* Text inside parentheses */}
      {parts[2]} {/* Text after parentheses */}
    </>
  );
};


  const popupRef = useRef(null);



  const [activeItems, setActiveItems] = useState([]);

  const handleClickOutside = (event) => {
        // If the click happens outside the popup and it's open, close it
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            closePopup(event);
        }
    };

    // Close popup when clicking outside (even if the user didn't interact with it)
    useEffect(() => {
        if (showPopup) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside); // Cleanup
        };
    }, [showPopup]);

    const clearTrialDependentSelections = () => {
      if (!isTrial) {
        if (downloadPermissions === "Pay") {
          setDownloadPermissions(""); // Clear download permissions
        }
        if (filmRetention === "4 Weeks") {
          setFilmRetention(""); // Clear film retention
        }
        if (numAthletes === "1-10") {
          setNumAthletes(""); // Clear number of athletes
        }
      }
    };

    useEffect(() => {
     clearTrialDependentSelections();
    }, [isTrial, downloadPermissions, filmRetention, numAthletes]);



  const handleClick = (item) => {
    if (activeItems.includes(item)) {
      setActiveItems(activeItems.filter((activeItem) => activeItem !== item));
    } else {
      setActiveItems([...activeItems, item]);
    }  };

const elementRefs = useRef({
    First: React.createRef(),
    Second: React.createRef(),
    Third: React.createRef(),
    Fourth: React.createRef(),
    Fifth: React.createRef(),
    Sixth: React.createRef(),
    Seventh: React.createRef(),
    Eighth: React.createRef(),
    Ninth: React.createRef(),
    Tenth: React.createRef(),
    Eleventh: React.createRef(),
    Twelfth: React.createRef(),
    Thirteenth: React.createRef(),
    Fourteenth: React.createRef(),
    Fifteenth: React.createRef(),
    Sixteenth: React.createRef(),
  });

  const previousActiveItemsRef = useRef([]); // To keep track of the previous state

  useEffect(() => {
    const lastClickedItem = activeItems[activeItems.length - 1]; // Get the last clicked item
    const previousActiveItems = previousActiveItemsRef.current;

    if (activeItems.length > previousActiveItems.length && lastClickedItem && elementRefs.current[lastClickedItem]) {
      const element = elementRefs.current[lastClickedItem].current;
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }

    // Update the previous active items reference
    previousActiveItemsRef.current = activeItems;
  }, [activeItems]);



  return (
    <div className="purchase-page-template">

        {showPopup && <div className="overlay-pop"></div>}

        <div className="product-img-box">
            <ImageCarousel
                images = {productimages}
            />
        </div>
        <div className="product-info">
            <div className="product-name">
                {productname}
            </div>
            <div className="product-description">
                {description}
            </div>
            <div className="product-description">
                {description2}
            </div>

            <div className="product-features">
                <div className="feature">
                    Features
                </div>
                <ul>
                    {features.map((point, index) => (
                        <li key={index}>{point}</li>
                    ))}
                </ul>
            </div>
            <div className="subscription-box">
                <div className="Section-title">
                    Hardware
                </div>
                <div className="build-your-own">
                    Choose a Hardware Type:
                    <div className="subscription-row">
                        <div
                            className={`basic-sub ${hardware === "Basic" ? "selected" : ""}`}
                            onClick={() => setHardware("Basic")}
                        >
                            <img
                                className="info-icon"
                                src={require("../../static/img/infobutton2.png")}
                                alt="info button"
                                onClick={(event) => togglePopup(event, "BasicHardware")} // Toggle popup for Basic hardware
                            />
                            {showPopup && activePopup === "BasicHardware" && (
                                <div
                                    className="popup"
                                    ref={popupRef}
                                    onMouseLeave={closePopup}
                                    onClick={(event) => event.stopPropagation()} // Prevent bubbling
                                >
                                    <h4>Basic Model</h4>
                                    <ul>
                                        <li onClick={() => handleClick("First")}>
                                            Standard Hardware {activeItems.includes("First") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("First") && (
                                            <li className="description-box">
                                                {infoDictionary["Basic1"]}
                                            </li>
                                        )}
                                        <li onClick={() => handleClick("Second")}>
                                            Speed {activeItems.includes("Second") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Second") && (
                                            <li className="description-box">
                                                {infoDictionary["Basic2"]}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                            Basic
                            <div className="grey-price">
                                {leaseOrPurchase === "Lease" && hardware === "Premium" && "-$200"}
                                {leaseOrPurchase === "Lease" && hardware !== "Premium" && hardware !== "Basic" && "+$400"}
                                {leaseOrPurchase === "Purchase" && hardware === "Premium" && "-$2000"}
                                {leaseOrPurchase === "Purchase" && hardware !== "Premium" && hardware !== "Basic" && "+$10000"}


                            </div>
                        </div>
                        <div
                            className={`premium-sub ${hardware === "Premium" ? "selected" : ""}`}
                            onClick={() => setHardware("Premium")}
                        >
                            <img
                                className="info-icon"
                                src={require("../../static/img/infobutton2.png")}
                                alt="info button"
                                onClick={(event) => togglePopup(event, "PremiumHardware")} // Toggle popup for Premium hardware
                            />
                            {showPopup && activePopup === "PremiumHardware" && (
                                <div
                                    className="popup"
                                    ref={popupRef}
                                    onMouseLeave={closePopup}
                                    onClick={(event) => event.stopPropagation()} // Prevent bubbling
                                >
                                    <h4>Premium Model</h4>
                                    <ul>
                                        <li onClick={() => handleClick("First")}>
                                            Premium Hardware {activeItems.includes("First") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("First") && (
                                            <li className="description-box">
                                                {infoDictionary["Premium1"]}
                                            </li>
                                        )}
                                        <li onClick={() => handleClick("Second")}>
                                            Speed {activeItems.includes("Second") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Second") && (
                                            <li className="description-box">
                                                {infoDictionary["Premium2"]}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                            Premium
                            <div className="grey-price">
                                {leaseOrPurchase === "Lease" && hardware === "Basic" && "+$200"}
                                {leaseOrPurchase === "Lease" && hardware !== "Premium" && hardware !== "Basic" && "+$600"}
                                {leaseOrPurchase === "Purchase" && hardware === "Basic" && "+$2000"}
                                {leaseOrPurchase === "Purchase" && hardware !== "Premium" && hardware !== "Basic" && "+$12000"}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="build-your-own">
                    Lease or Purchase?
                    <div className="subscription-row">
                        <div
                            className={`basic-sub ${leaseOrPurchase === "Lease" ? "selected" : ""}`}
                            onClick={() => setLeaseOrPurchase("Lease")}
                        >
                            Lease
                        </div>
                        <div
                            className={`premium-sub ${leaseOrPurchase === "Purchase" ? "selected" : ""}`}
                            onClick={() => setLeaseOrPurchase("Purchase")}
                        >
                            Purchase
                        </div>
                    </div>
                </div>
                <div className="Section-title">
                    Software
                </div>
                <div className="build-your-own">
                    Software Subscription
                    <div className="subscription-row">
                        <div
                            className={`premium-sub ${softwareSubscription === "Trial" ? "selected" : ""}`}
                            onClick={() => {
                                setSoftwareSubscription("Trial");
                                setNumAthletes("1-10");
                                setDownloadPermissions("Pay");
                                setFilmRetention("4 Weeks");
                            }}
                        >

                            <img
                                className="info-icon"
                                src={require("../../static/img/infobutton2.png")}
                                alt="info button"
                                onClick={(event) => togglePopup(event, "Trial")} // Toggle popup for Trial
                            />
                            {showPopup && activePopup === "Trial" && (
                                <div
                                    className="popup"
                                    ref={popupRef}
                                    onMouseLeave={closePopup}
                                    onClick={(event) => event.stopPropagation()} // Prevent bubbling
                                >
                                    <h4>Trial Version</h4>
                                    <ul>
                                        <li onClick={() => handleClick("First")}>
                                            Film {activeItems.includes("First") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("First") && (
                                            <li className="description-box">
                                                {infoDictionary["Trial1"]}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}


                            Trial
                            <div className="grey-price">
                                {softwareSubscription === "Basic" && "-$10"}
                                {softwareSubscription === "Standard" && "-$75"}
                                {softwareSubscription === "Professional" && "-$275"}
                                {softwareSubscription === "Olympic" && "-$350"}
                                {softwareSubscription === "" && "$0"}
                            </div>


                        </div>


                        <div
                            className={`premium-sub ${softwareSubscription === "Basic" ? "selected" : ""}`}
                            onClick={() => setSoftwareSubscription("Basic")}
                        >
                            <img
                                className="info-icon"
                                src={require("../../static/img/infobutton2.png")}
                                alt="info button"
                                onClick={(event) => togglePopup(event, "Basic")} // Toggle popup for Basic
                            />
                            {showPopup && activePopup === "Basic" && (
                                <div
                                    className="popup"
                                    ref={popupRef}
                                    onMouseLeave={closePopup}
                                    onClick={(event) => event.stopPropagation()} // Prevent bubbling
                                >
                                    <h4>Basic Version</h4>
                                    <ul>
                                        <li onClick={() => handleClick("First")}>
                                            Film {activeItems.includes("First") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("First") && (
                                            <li className="description-box">
                                                {infoDictionary["BasicSoft1"]}
                                            </li>
                                        )}
                                        <li onClick={() => handleClick("Second")}>
                                            Speed {activeItems.includes("Second") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Second") && (
                                            <li className="description-box">
                                                {infoDictionary["BasicSoft2"]}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}


                            Basic
                            <div className="grey-price">
                                {softwareSubscription === "Trial" && "+$10"}
                                {softwareSubscription === "Standard" && "-$65"}
                                {softwareSubscription === "Professional" && "-$265"}
                                {softwareSubscription === "Olympic" && "-$340"}
                                {softwareSubscription === "" && "+$10"}
                            </div>
                        </div>
                        <div
                            className={`premium-sub ${softwareSubscription === "Standard" ? "selected" : ""}`}
                            onClick={() => setSoftwareSubscription("Standard")}
                        >
                            <img
                                className="info-icon"
                                src={require("../../static/img/infobutton2.png")}
                                alt="info button"
                                onClick={(event) => togglePopup(event, "Standard")} // Toggle popup for Standard
                            />
                            {showPopup && activePopup === "Standard" && (
                                <div
                                    className="popup"
                                    ref={popupRef}
                                    onMouseLeave={closePopup}
                                    onClick={(event) => event.stopPropagation()} // Prevent bubbling
                                >
                                    <h4>Standard Software</h4>
                                    <ul>
                                        <li onClick={() => handleClick("First")}>
                                            Speed {activeItems.includes("First") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("First") && (
                                            <li className="description-box" ref={elementRefs.current.First}>
                                                {highlightParentheses(infoDictionary["Standard1"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Second")}>
                                            Strokes {activeItems.includes("Second") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Second") && (
                                            <li className="description-box" ref={elementRefs.current.Second}>
                                                {highlightParentheses(infoDictionary["Standard2"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Third")}>
                                            Strokes per 50 {activeItems.includes("Third") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Third") && (
                                            <li className="description-box" ref={elementRefs.current.Third}>
                                                {highlightParentheses(infoDictionary["Standard3"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Fourth")}>
                                            Tempo {activeItems.includes("Fourth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Fourth") && (
                                            <li className="description-box" ref={elementRefs.current.Fourth}>
                                                {highlightParentheses(infoDictionary["Standard4"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Fifth")}>
                                            Tempo Deviation {activeItems.includes("Fifth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Fifth") && (
                                            <li className="description-box" ref={elementRefs.current.Fifth}>
                                                {highlightParentheses(infoDictionary["Standard5"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Sixth")}>
                                            Tempo Falloff {activeItems.includes("Sixth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Sixth") && (
                                            <li className="description-box" ref={elementRefs.current.Sixth}>
                                                {highlightParentheses(infoDictionary["Standard6"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Seventh")}>
                                            Distance per Stroke {activeItems.includes("Seventh") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Seventh") && (
                                            <li className="description-box" ref={elementRefs.current.Seventh}>
                                                {highlightParentheses(infoDictionary["Standard7"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Eighth")}>
                                            Stroke Index {activeItems.includes("Eighth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Eighth") && (
                                            <li className="description-box" ref={elementRefs.current.Eighth}>
                                                {highlightParentheses(infoDictionary["Standard8"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Ninth")}>
                                            Stroke Rate {activeItems.includes("Ninth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Ninth") && (
                                            <li className="description-box" ref={elementRefs.current.Ninth}>
                                                {highlightParentheses(infoDictionary["Standard9"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Tenth")}>
                                            Breaths {activeItems.includes("Tenth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Tenth") && (
                                            <li className="description-box" ref={elementRefs.current.Tenth}>
                                                {highlightParentheses(infoDictionary["Standard10"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Eleventh")}>
                                            Breaths per 50 {activeItems.includes("Eleventh") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Eleventh") && (
                                            <li className="description-box" ref={elementRefs.current.Eleventh}>
                                                {highlightParentheses(infoDictionary["Standard11"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Twelfth")}>
                                            Breath Travel {activeItems.includes("Twelfth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Twelfth") && (
                                            <li className="description-box" ref={elementRefs.current.Twelfth}>
                                                {highlightParentheses(infoDictionary["Standard12"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Thirteenth")}>
                                            Breath Distance {activeItems.includes("Thirteenth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Thirteenth") && (
                                            <li className="description-box" ref={elementRefs.current.Thirteenth}>
                                                {highlightParentheses(infoDictionary["Standard13"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Fourteenth")}>
                                            Breath Speed {activeItems.includes("Fourteenth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Fourteenth") && (
                                            <li className="description-box" ref={elementRefs.current.Fourteenth}>
                                                {highlightParentheses(infoDictionary["Standard14"])}
                                            </li>
                                        )}
                                    </ul>

                                </div>
                            )}

                            Standard
                            <div className="grey-price">
                                {softwareSubscription === "Trial" && "+$75"}
                                {softwareSubscription === "Basic" && "+$65"}
                                {softwareSubscription === "Professional" && "-$200"}
                                {softwareSubscription === "Olympic" && "-$275"}
                                {softwareSubscription === "" && "+75"}
                            </div>
                        </div>
                        <div
                            className={`premium-sub ${softwareSubscription === "Professional" ? "selected" : ""}`}
                            onClick={() => setSoftwareSubscription("Professional")}
                        >
                            <img
                                className="info-icon"
                                src={require("../../static/img/infobutton2.png")}
                                alt="info button"
                                onClick={(event) => togglePopup(event, "Professional")} // Toggle popup for Professional
                            />
                            {showPopup && activePopup === "Professional" && (
                                <div
                                    className="popup"
                                    ref={popupRef}
                                    onMouseLeave={closePopup}
                                    onClick={(event) => event.stopPropagation()} // Prevent bubbling
                                >
                                    <h4>Professional Software</h4>
                                    <ul>
                                        <li>
                                            All Standard Features
                                        </li>

                                        <li onClick={() => handleClick("First")}>
                                            SR - Cycle Start {activeItems.includes("First") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("First") && (
                                            <li className="description-box" ref={elementRefs.current.First}>
                                                {highlightParentheses(infoDictionary["Pro1"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Second")}>
                                            SR - Cycle End {activeItems.includes("Second") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Second") && (
                                            <li className="description-box" ref={elementRefs.current.Second}>
                                                {highlightParentheses(infoDictionary["Pro2"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Third")}>
                                            SR - Cycle Mid {activeItems.includes("Third") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Third") && (
                                            <li className="description-box" ref={elementRefs.current.Third}>
                                                {highlightParentheses(infoDictionary["Pro3"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Fourth")}>
                                            SWOLF {activeItems.includes("Fourth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Fourth") && (
                                            <li className="description-box" ref={elementRefs.current.Fourth}>
                                                {highlightParentheses(infoDictionary["Pro4"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Fifth")}>
                                            Overwater Speed {activeItems.includes("Fifth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Fifth") && (
                                            <li className="description-box" ref={elementRefs.current.Fifth}>
                                                {highlightParentheses(infoDictionary["Pro5"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Sixth")}>
                                            Overwater Time {activeItems.includes("Sixth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Sixth") && (
                                            <li className="description-box" ref={elementRefs.current.Sixth}>
                                                {highlightParentheses(infoDictionary["Pro6"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Seventh")}>
                                            Underwater Speed {activeItems.includes("Seventh") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Seventh") && (
                                            <li className="description-box" ref={elementRefs.current.Seventh}>
                                                {highlightParentheses(infoDictionary["Pro7"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Eighth")}>
                                            Underwater Time {activeItems.includes("Eighth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Eighth") && (
                                            <li className="description-box" ref={elementRefs.current.Eighth}>
                                                {highlightParentheses(infoDictionary["Pro8"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Ninth")}>
                                            Percentage Underwater {activeItems.includes("Ninth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Ninth") && (
                                            <li className="description-box" ref={elementRefs.current.Ninth}>
                                                {highlightParentheses(infoDictionary["Pro9"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Tenth")}>
                                            Max Depth Underwater {activeItems.includes("Tenth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Tenth") && (
                                            <li className="description-box" ref={elementRefs.current.Tenth}>
                                                {highlightParentheses(infoDictionary["Pro10"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Eleventh")}>
                                            Rotation {activeItems.includes("Eleventh") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Eleventh") && (
                                            <li className="description-box" ref={elementRefs.current.Eleventh}>
                                                {highlightParentheses(infoDictionary["Pro11"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Twelfth")}>
                                            Transition Time {activeItems.includes("Twelfth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Twelfth") && (
                                            <li className="description-box" ref={elementRefs.current.Twelfth}>
                                                {highlightParentheses(infoDictionary["Pro12"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Thirteenth")}>
                                            Breakout Time {activeItems.includes("Thirteenth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Thirteenth") && (
                                            <li className="description-box" ref={elementRefs.current.Thirteenth}>
                                                {highlightParentheses(infoDictionary["Pro13"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Fourteenth")}>
                                            Number of Kicks
                                            (Underwater) {activeItems.includes("Fourteenth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Fourteenth") && (
                                            <li className="description-box" ref={elementRefs.current.Fourteenth}>
                                                {highlightParentheses(infoDictionary["Pro14"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Fifteenth")}>
                                            Kick Rate {activeItems.includes("Fifteenth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Fifteenth") && (
                                            <li className="description-box" ref={elementRefs.current.Fifteenth}>
                                                {highlightParentheses(infoDictionary["Pro15"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Sixteenth")}>
                                            Kick Tempo {activeItems.includes("Sixteenth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Sixteenth") && (
                                            <li className="description-box" ref={elementRefs.current.Sixteenth}>
                                                {highlightParentheses(infoDictionary["Pro16"])}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                            Professional
                            <div className="grey-price">
                                {softwareSubscription === "Trial" && "+$275"}
                                {softwareSubscription === "Basic" && "+$265"}
                                {softwareSubscription === "Standard" && "+$200"}
                                {softwareSubscription === "Olympic" && "-$75"}
                                {softwareSubscription === "" && "+275"}
                            </div>
                        </div>
                        <div
                            className={`premium-sub ${softwareSubscription === "Olympic" ? "selected" : ""}`}
                            onClick={() => setSoftwareSubscription("Olympic")}
                        >
                            <img
                                className="info-icon"
                                src={require("../../static/img/infobutton2.png")}
                                alt="info button"
                                onClick={(event) => togglePopup(event, "Olympic")} // Toggle popup for Olympic
                            />
                            {showPopup && activePopup === "Olympic" && (
                                <div
                                    className="popup"
                                    ref={popupRef}
                                    onMouseLeave={closePopup}
                                    onClick={(event) => event.stopPropagation()} // Prevent bubbling
                                >
                                    <h4>Olympic Software</h4>
                                    <ul>
                                        <li>
                                            All Professional Features
                                        </li>

                                        <li onClick={() => handleClick("First")}>
                                            Push-Off Depth {activeItems.includes("First") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("First") && (
                                            <li className="description-box" ref={elementRefs.current.First}>
                                                {highlightParentheses(infoDictionary["Olympic1"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Second")}>
                                            Turn Time {activeItems.includes("Second") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Second") && (
                                            <li className="description-box" ref={elementRefs.current.Second}>
                                                {highlightParentheses(infoDictionary["Olympic2"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Third")}>
                                            Push-Off Time {activeItems.includes("Third") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Third") && (
                                            <li className="description-box" ref={elementRefs.current.Third}>
                                                {highlightParentheses(infoDictionary["Olympic3"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Fourth")}>
                                            Push Max Acceleration {activeItems.includes("Fourth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Fourth") && (
                                            <li className="description-box" ref={elementRefs.current.Fourth}>
                                                {highlightParentheses(infoDictionary["Olympic4"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Fifth")}>
                                            Push Max Strength {activeItems.includes("Fifth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Fifth") && (
                                            <li className="description-box" ref={elementRefs.current.Fifth}>
                                                {highlightParentheses(infoDictionary["Olympic5"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Sixth")}>
                                            Push Avg Strength {activeItems.includes("Sixth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Sixth") && (
                                            <li className="description-box" ref={elementRefs.current.Sixth}>
                                                {highlightParentheses(infoDictionary["Olympic6"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Seventh")}>
                                            Push Max Speed {activeItems.includes("Seventh") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Seventh") && (
                                            <li className="description-box" ref={elementRefs.current.Seventh}>
                                                {highlightParentheses(infoDictionary["Olympic7"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Eighth")}>
                                            Kick Precision {activeItems.includes("Eighth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Eighth") && (
                                            <li className="description-box" ref={elementRefs.current.Eighth}>
                                                {highlightParentheses(infoDictionary["Olympic8"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Ninth")}>
                                            Dive Time {activeItems.includes("Ninth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Ninth") && (
                                            <li className="description-box" ref={elementRefs.current.Ninth}>
                                                {highlightParentheses(infoDictionary["Olympic9"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Tenth")}>
                                            Dive Hang Time {activeItems.includes("Tenth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Tenth") && (
                                            <li className="description-box" ref={elementRefs.current.Tenth}>
                                                {highlightParentheses(infoDictionary["Olympic10"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Eleventh")}>
                                            Dive Block Time (Reaction
                                            Time) {activeItems.includes("Eleventh") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Eleventh") && (
                                            <li className="description-box" ref={elementRefs.current.Eleventh}>
                                                {highlightParentheses(infoDictionary["Olympic11"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Twelfth")}>
                                            Finger Height {activeItems.includes("Twelfth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Twelfth") && (
                                            <li className="description-box" ref={elementRefs.current.Twelfth}>
                                                {highlightParentheses(infoDictionary["Olympic12"])}
                                            </li>
                                        )}

                                        <li onClick={() => handleClick("Thirteenth")}>
                                            Hand Drag {activeItems.includes("Thirteenth") ? "↑" : "↓"}
                                        </li>
                                        {activeItems.includes("Thirteenth") && (
                                            <li className="description-box" ref={elementRefs.current.Thirteenth}>
                                                {highlightParentheses(infoDictionary["Olympic13"])}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                            Olympic
                            <div className="grey-price">
                                {softwareSubscription === "Trial" && "+$350"}
                                {softwareSubscription === "Basic" && "+$340"}
                                {softwareSubscription === "Standard" && "+$200"}
                                {softwareSubscription === "Professional" && "+$75"}
                                {softwareSubscription === "" && "+350"}
                            </div>
                        </div>
                    </div>
                </div>

                {isSoftwareSelected && isTrial && (
                    <div className="build-your-own">
                        Number of Athletes

                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${numAthletes === "1-10" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("1-10")}
                            >
                                1-10
                                <div className="grey-price">
                                    {numAthletes === "" && "$0"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isNumAthletesSelected && isTrial && (
                    <div className="build-your-own">
                        Film Retention
                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${filmRetention === "4 Weeks" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("4 Weeks")}
                            >
                                4 Weeks
                                <div className="grey-price">
                                    {filmRetention === "" && "$0"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isFilmRetentionSelected && isTrial && (
                    <div className="build-your-own">
                        Download Permissions
                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${downloadPermissions === "Pay" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("Pay")}
                            >
                                None
                                <div className="grey-price">
                                    {downloadPermissions === "" && "$0"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {isSoftwareSelected && !isTrial && (
                    <div className="build-your-own">
                        Number of Athletes

                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${numAthletes === "1-25" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("1-25")}
                            >
                                1-25
                                <div className="grey-price">
                                    {numAthletes === "26-50" && "-$50"}
                                    {numAthletes === "51-100" && "-$100"}
                                    {numAthletes === "101-200" && "-$200"}
                                    {numAthletes === "" && "$0"}

                                </div>
                            </div>


                            <div
                                className={`premium-sub ${numAthletes === "26-50" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("26-50")}
                            >
                                26-50
                                <div className="grey-price">
                                    {numAthletes === "1-25" && "+$50"}
                                    {numAthletes === "51-100" && "-$50"}
                                    {numAthletes === "101-200" && "-$150"}
                                    {numAthletes === "" && "$50"}

                                </div>

                            </div>
                            <div
                                className={`premium-sub ${numAthletes === "51-100" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("51-100")}
                            >
                                51-100
                                <div className="grey-price">
                                    {numAthletes === "1-25" && "+$100"}
                                    {numAthletes === "26-50" && "+$50"}
                                    {numAthletes === "101-200" && "-$100"}
                                    {numAthletes === "" && "$100"}

                                </div>
                            </div>
                            <div
                                className={`premium-sub ${numAthletes === "101-200" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("101-200")}
                            >
                                101-200
                                <div className="grey-price">
                                    {numAthletes === "1-25" && "+$200"}
                                    {numAthletes === "26-50" && "+$150"}
                                    {numAthletes === "51-100" && "+$100"}
                                    {numAthletes === "" && "$200"}

                                </div>

                            </div>

                            <div
                                className={`premium-sub ${numAthletes === "200+" ? "selected" : ""}`}
                                onClick={() => setNumAthletes("200+")}
                            >
                                201+
                                <div className="grey-price">
                                    Custom

                                </div>

                            </div>

                        </div>
                    </div>
                )}
                {isNumAthletesSelected && !isTrial && (
                    <div className="build-your-own">
                        Film Retention
                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${filmRetention === "2 Months" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("2 Months")}
                            >
                                2 Months
                                <div className="grey-price">
                                    {filmRetention === "" && "$0"}
                                    {filmRetention === "4 Months" && "-$50"}
                                    {filmRetention === "6 Months" && "-$80"}
                                    {filmRetention === "12 Months" && "-$90"}
                                    {filmRetention === "Forever" && "-$150"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${filmRetention === "4 Months" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("4 Months")}
                            >
                                4 Months
                                <div className="grey-price">
                                    {filmRetention === "" && "$50"}
                                    {filmRetention === "2 Months" && "+$50"}
                                    {filmRetention === "6 Months" && "-$30"}
                                    {filmRetention === "12 Months" && "-$40"}
                                    {filmRetention === "Forever" && "-$100"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${filmRetention === "6 Months" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("6 Months")}
                            >
                                6 Months
                                <div className="grey-price">
                                    {filmRetention === "" && "$80"}
                                    {filmRetention === "2 Months" && "+$80"}
                                    {filmRetention === "4 Months" && "+$30"}
                                    {filmRetention === "12 Months" && "-$10"}
                                    {filmRetention === "Forever" && "-$70"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${filmRetention === "12 Months" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("12 Months")}
                            >
                                12 Months
                                <div className="grey-price">
                                    {filmRetention === "" && "$90"}
                                    {filmRetention === "2 Months" && "+$90"}
                                    {filmRetention === "4 Months" && "+$40"}
                                    {filmRetention === "6 Months" && "+$10"}
                                    {filmRetention === "Forever" && "-$60"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${filmRetention === "Forever" ? "selected" : ""}`}
                                onClick={() => setFilmRetention("Forever")}
                            >
                                Forever
                                <div className="grey-price">
                                    {filmRetention === "" && "$150"}
                                    {filmRetention === "2 Months" && "-$150"}
                                    {filmRetention === "4 Months" && "-$100"}
                                    {filmRetention === "6 Months" && "-$70"}
                                    {filmRetention === "12 Months" && "+$60"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isFilmRetentionSelected && !isTrial && (
                    <div className="build-your-own">
                        Download Permissions
                        <div className="subscription-row">
                            <div
                                className={`premium-sub ${downloadPermissions === "Pay" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("Pay")}
                            >
                                None
                                <div className="grey-price">
                                    {downloadPermissions === "" && "$0"}
                                    {downloadPermissions === "5/mo" && "-$23"}
                                    {downloadPermissions === "10/mo" && "-$45"}
                                    {downloadPermissions === "20/mo" && "-$85"}
                                    {downloadPermissions === "50/mo" && "-$200"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${downloadPermissions === "5/mo" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("5/mo")}
                            >
                                5/month
                                <div className="grey-price">
                                    {downloadPermissions === "" && "$23"}
                                    {downloadPermissions === "Pay" && "-$23"}
                                    {downloadPermissions === "10/mo" && "-$22"}
                                    {downloadPermissions === "20/mo" && "-$62"}
                                    {downloadPermissions === "50/mo" && "-$177"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${downloadPermissions === "10/mo" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("10/mo")}
                            >
                                10/month
                                <div className="grey-price">
                                    {downloadPermissions === "" && "$45"}
                                    {downloadPermissions === "Pay" && "+$45"}
                                    {downloadPermissions === "5/mo" && "+$22"}
                                    {downloadPermissions === "20/mo" && "-$40"}
                                    {downloadPermissions === "50/mo" && "+$155"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${downloadPermissions === "20/mo" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("20/mo")}
                            >
                                20/month
                                <div className="grey-price">
                                    {downloadPermissions === "" && "$85"}
                                    {downloadPermissions === "5/mo" && "+$62"}
                                    {downloadPermissions === "10/mo" && "+$40"}
                                    {downloadPermissions === "50/mo" && "-$115"}
                                    {downloadPermissions === "Pay" && "+$85"}
                                </div>
                            </div>
                            <div
                                className={`premium-sub ${downloadPermissions === "50/mo" ? "selected" : ""}`}
                                onClick={() => setDownloadPermissions("50/mo")}
                            >
                                50/month
                                <div className="grey-price">
                                    {downloadPermissions === "" && "$200"}
                                    {downloadPermissions === "5/mo" && "+$177"}
                                    {downloadPermissions === "10/mo" && "+$145"}
                                    {downloadPermissions === "20/mo" && "+$115"}
                                    {downloadPermissions === "Pay" && "+$200"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="purchase-container">
                <div className="product-price">
                    {UpfrontPrice !== 0 && `${formattedUpfrontPrice}`}
                    {UpfrontPrice !== 0 && totalPrice !== 0 && ' + '}
                    {totalPrice !== 0 && `${formattedTotalPrice} /month`}
                </div>

                {(hardware && leaseOrPurchase && softwareSubscription && numAthletes && filmRetention && downloadPermissions) && (
                    <div className="purchase-summary">
                        {/*<p className="order-summary-title"><strong>Order Summary:</strong></p>*/}

                        {/* Main Selections */}
                        <p className="main-summary">
                            You have chosen
                            to <strong>{leaseOrPurchase}</strong> the <strong>{hardware}</strong> hardware
                            with a <strong>{softwareSubscription} software package</strong>.
                        </p>

                        {/* Additional Features */}
                        <div className="additional-features">
                            {numAthletes && <p> Your plan
                                 covers <strong>{numAthletes} athletes</strong>.</p>}
                            {filmRetention && <p> You will have access to each video
                                for <strong>{filmRetention}</strong>.</p>}
                            {downloadPermissions && (
                              <p>
                                {downloadPermissions === "Pay"
                                  ? "You can purchase downloads of your film."
                                  : <>You can download up to <strong>{downloadPermissions}</strong>.</>
                                }
                              </p>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="purchase-box">
                <div className="quantity-selector">
                    <button className="decrease-qnt" onClick={handleDecrease}>
                        <img className="selector-img" src={require("../../static/img/---icon--minus-cirlce-@2x.png")}
                             alt="icon &quot;minus cirlce&quot;"/>
                    </button>
                    <div className="qnt-count">
                        {quantity}
                    </div>
                    <button className="increase-qnt" onClick={handleIncrease}>
                        <img className="selector-img" src={require("../../static/img/---icon--add-circle-@2x.png")}
                             alt="icon &quot;add circle&quot;"/>
                    </button>
                </div>
                <button className="purchase-button" disabled={!isPurchaseEnabled()} onClick={handlePurchaseClick}>
                    Purchase
                </button>
            </div>
            {purchaseCompleted && <div className="purchase-complete">Enjoy the Swimbot!</div>}

        </div>
    </div>
  );
}

export default PurchaseProductTemplate;
