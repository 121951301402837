import React from 'react';
import './SignUpButton.css'

function SignUpButton() {
  return (
    <a href="/sign-up">
        <button className="signup-button"> Sign up </button>
    </a>
  );
}

export default SignUpButton;
