import React, { Component } from "react";
import Slider from "react-slick";

import "../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../node_modules/slick-carousel/slick/slick.css"; 
import "./ImageCarousel.css";



export default class ImageCarousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    };

    const { images } = this.props;
    
    return (
        <div className="image-carousel">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} className="image-container">
                      <img className="slider-image" src={image} alt={`Slide ${index}`} />
                    </div>
                 ))}
            </Slider>
        </div>
    );
  }
}