import React, { useState } from "react";
import { useParams, useHistory, useLocation, Redirect } from "react-router-dom";
import "./GetStarted2.css";
import HomeLogo from "../../components/HomeLogo";
import GetStartedTemplate2 from "../../components/GetStartedTemplate2";
import { BASE_URL } from "../../constants/company-info";

function GetStarted2() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  let { role } = useParams();
  const history = useHistory();

  const location = useLocation();
  const userId = location.state?.userId;
  const sport = location.state?.sport;

  const isCoach_str = localStorage.getItem('is_coach');

  if (role !== 'coach' && role !== 'athlete' || isCoach_str !== 'TBD' ) {
    if (isCoach_str === 'false') {
      return <Redirect to='/my-film' />
    }
    else {
      return <Redirect to='/manage-teams' />
    }
  };

  const allFieldsFilled = firstName && lastName;

  const handleNextPage = () => {

    // Update user's name
    const data = {
      "user_id": userId,
      "first_name": firstName,
      "last_name": lastName
    };

    fetch(BASE_URL + '/api/update_name/', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
            // route on successful registration

            if (role === "coach") {
              history.push({
                pathname: '/get-started-coach',
                state: { userId: userId, sport: sport, role: role, name: firstName + " " + lastName }
              });
            } else if (role === "athlete") {
              history.push({
                pathname: '/get-started-athlete',
                state: { userId: userId, sport: sport, role: role }
              });
            }
        })
        .catch(error => {
            alert(error)
            console.error('Error registering user: ', error);
        });
  }

  const formContent = (
    <div className="form">
      <label style={{"marginTop": "15px"}}>First Name</label>
      <input
          type="text"
          className="form-control mt-1"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => {setFirstName(e.target.value);}}
      />
      <label>Last Name</label>
      <input
          type="text"
          className="form-control mt-1"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => {setLastName(e.target.value);}}
      />
      <button
          className="submit-log-in"
          disabled={!allFieldsFilled}
          onClick={handleNextPage}
          style={{"marginTop": "30px"}}
        >
          Get Started
        </button>
    </div>
  );

  return (
    <div className="login-page">
      <div className='background'></div>
      <HomeLogo />
      <GetStartedTemplate2
        title={"Get Started!"}
        subtitle={`Create your ${role} profile`}
        form={formContent}
      />
    </div>
  );
}

export default GetStarted2;
