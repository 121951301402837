import React, { useState } from "react";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import "./GetStarted.css";
import HomeLogo from "../../components/HomeLogo";
import GetStartedTemplate from "../../components/GetStartedTemplate";


function GetStarted() {
  const [sport, setSport] = useState("");
  const [role, setRole] = useState("");
  const history = useHistory();

  const location = useLocation();
  const userId = location.state?.userId;
  const isCoach_str = localStorage.getItem('is_coach');

  if (isCoach_str !== 'TBD') {
    if (isCoach_str === 'false') {
      return <Redirect to='/my-film' />
    }
    else {
      return <Redirect to='/manage-teams' />
    }
  };

  const handleSportSelection = () => {
    setSport("Swimming");
  };

  const handleRoleSelection = (selectedRole) => {
    setRole(selectedRole);
  };

  const handleNextPage = () => {
    history.push({
      pathname: `/get-started-2/${role.toLowerCase()}`,
      state: { userId: userId, sport: sport}
    });
  };

  const allFieldsFilled = sport && role;

  const formContent = (
    <div className="form">
      <label className="getStarted-label">Please select your sport</label>
      <div className="button-group">
        <button className={`select-btn btn ${sport === "Swimming" ? "selected" : ""}`} onClick={handleSportSelection}>Swimming</button>
      </div>
      <label className="getStarted-label" style={{"marginTop": "15px"}}>I am a...</label>
      <div className="button-group">
        <button className={`select-btn btn ${role === "Coach" ? "selected" : ""}`} onClick={() => handleRoleSelection("Coach")}>Coach</button>
        <button className={`select-btn btn ${role === "Athlete" ? "selected" : ""}`} onClick={() => handleRoleSelection("Athlete")}>Athlete</button>
      </div>
      <button
          className="submit-log-in"
          disabled={!allFieldsFilled}
          onClick={handleNextPage}
          style={{"marginTop": "30px"}}
        >
          Get Started
        </button>
    </div>
  );

  return (
    <div className="login-page">
      <div className='background'></div>
      <HomeLogo />
      <GetStartedTemplate
        title={"Get Started!"}
        form={formContent}
      />
    </div>
  );
}

export default GetStarted;
