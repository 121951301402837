import React from 'react';
import { COMPANY_NAME, COMPANY_DESC } from '../../constants/company-info';
import './HomeHeader.css';

function HomeHeader() {
  return (
    <div className="header">
        <div className="company-name">
            <span>{COMPANY_NAME.FIRST}</span>
        </div>
        <p className="company-description">{COMPANY_DESC}</p>
    </div>
  );
}

export default HomeHeader;
