import React, { Component } from "react";
import Slider from "react-slick";
import ProductBox from "../../components/ProductBox";

import "../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../node_modules/slick-carousel/slick/slick.css"; 
import "./ProductCarousel.css";


export default class ProductCarousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    };
    return (
        <div className="slider-box">
            <Slider {...settings}>
                {/*
            <div className="slider-content">
            <ProductBox
                    img = "mask-group-1@2x.png"
                    title = "SwingBot"
                    buttoncolor = "linear-gradient(#60b177 0%, #147747 100%)"
                    buttontext = "Learn more"
                    link = 'swingbot'
                />
            </div>

            <div className="slider-content">
            <ProductBox
                    img = "mask-group-2@2x.png"
                    title = "SprintBot"
                    buttoncolor = "linear-gradient(#b072d6 0%, #7433a8 100%)"
                    buttontext = "Learn more"
                    link = 'sprintbot'
                />
            </div>
                */}
            <div className="slider-content">
            <ProductBox
                    img = "mask-group@2x.png"
                    title = "SwimmerBot"
                    buttoncolor = "linear-gradient(#308eac 0%, #376397 100%)"
                    buttontext = "Learn more"
                    link = '/swimbot'
                />
            </div>
            </Slider>
        </div>
    );
  }
}