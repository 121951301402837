import React, { useState } from "react";
import SignUpLogInButtonCluster from "../../components/SignUpLogInButtonCluster";
import HomeLogo from "../../components/HomeLogo";
import PurchaseProductTemplate from "../../components/PurchaseProductTemplate";
import "./SwimBot.css";


function SwimBot() {



      const productimages = [
        require("../../static/img/swimbotimg1.png"),
        require("../../static/img/swimbotimg1.png"),
        require("../../static/img/swimbotimg1.png"),
      ];

  return (
    <div className="swimbot-page">
        <div className="top-bar">
          <HomeLogo/>
          <SignUpLogInButtonCluster/>
        </div>
        {/*
        <div className="swimbot-overlap"> 
            <div class="swimbot-eclipse-1"></div>
            <div class="swimbot-eclipse-2"></div>
        </div>
        */}
        <PurchaseProductTemplate
            productname = {"SwimmerBot"}
            productimages = {productimages}
            description = {"SwimmerBot is the most cutting edge film and analytics tool available to swim teams that will propel your athletes to their fullest potentials. Try it out!"}
            description2={"SwimmerBot follows your athletes back and forth across the pool filming underwater to provide the most informative view of the swimmer's stroke. Set it down, tell it what to do, and get more out of your practice!" }
            features =    {[
                "Films swimmers autonomously",
                "Voice commanded",
                "Top speed to match even the fastest Olympic athletes",
                "Portable and compatible with almost every pool",
                "Film uploaded automatically",
                "Share film with teammates, coaches, and recruiters",
                "Reporting on up to 43 swimming metrics",
                "Suggested areas of improvement"
              ]}
        />


    </div>
  );
}

export default SwimBot;
