import React from 'react';
import './LogInButton.css'

function LogInButton() {
  return (
    <a href="/log-in">
        <button className="login-button"> Log in </button>
    </a>
  );
}

export default LogInButton;
