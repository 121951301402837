import React from 'react';
import './HeaderMotto.css'

function HeaderMotto() {
  return (
    <div className="manage-intro">
        Manage your teams.<br/>
        Analyze their performance.
    </div>
  );
}

export default HeaderMotto;
