import React from "react";
import "./ProductBox.css";

import maskGroup1 from '../../static/img/mask-group-1@2x.png';
import maskGroup2 from '../../static/img/mask-group-2@2x.png';
import maskGroup from '../../static/img/mask-group@2x.png';

const imageMap = {
  "mask-group-1@2x.png": maskGroup1,
  "mask-group-2@2x.png": maskGroup2,
  "mask-group@2x.png": maskGroup,
};

function ProductBox(props) {

    const {img, title, buttoncolor, buttontext, link, pageclassname} = props

    const buttonStyle = {
        background: buttoncolor,
    };

    const imageSrc = imageMap[img] || '';

  return (
    <div className="product-box">
        <img className="{pageclassname} product-image" src={imageSrc} alt="Mask group"></img>
        <p>{title}</p>
       <a href={link}><button className='product-button' style={buttonStyle}>{buttontext}</button></a>

        
    </div>
  );
}

export default ProductBox;
