import React from 'react';
import LogInButton from '../LogInButton';
import SignUpButton from '../SignUpButton';
import './SignUpLogInButtonCluster.css'

function SignUpLogInButtonCluster() {
  return (
    <div className="sign-log-button">
      {/* <LogInButton></LogInButton>
      <SignUpButton></SignUpButton> */}
    </div>
  );
}

export default SignUpLogInButtonCluster;
